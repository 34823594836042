import { createElement, cloneElement, render, Component, Fragment } from 'preact';
import { createPortal } from 'preact/compat';
import _ from 'lodash';
import { helpers } from "@cargo/common";

import SelectionStatus from "../.././overlay/selection-status";
import { subscribe, unsubscribe, dispatch } from '../../../customEvents';

import EyeRoll from './eye-roll'
import RotatingItem from './rotatingitem'
import FlyingObject from './flyingobject'
import ScrollTransition from './scroll-transition'
import Drag from './drag'
import Dropshadow from './dropshadow';
import Background from './background';
import InlinePartStyles from './inline-part-styles';
import Blink from './blink';
import { withScroll } from '../scroll-element';

const useList = new Set();

class UsesHost extends Component {
	constructor(props){
		super(props);

		if(!helpers.isServer) {
			this.observer = new MutationObserver(this.onAttributeChange);
		}

		this.usesOrder = ['blink', 'dropshadow', 'drag', 'rotation', 'eye-roll', 'scroll-animate', 'flying-object', 'inline-part-styles']

		this.state = {
			attributes: this.getAttributes(),
		};

		this.usesKeys = {
			'rotation': RotatingItem,
			'eye-roll': EyeRoll,
			'drag': Drag,
			'scroll-transition': ScrollTransition,
			'flying-object': FlyingObject,
			'dropshadow': Dropshadow,
			'background': Background,
			'inline-part-styles': InlinePartStyles,
			'blink': Blink,
		}

		switch(this.props.baseNode.tagName){
			case "DIV":
			case "SPAN":
			case "H1":
			case "H2":
			case "H3":
			case "H4":
			case "H5":
			case "H6":
			case "H7":
			case "H8":
			case "H9":
				this.displayInlineBlock  = true;
				break;

			default:
				this.displayInlineBlock = false;
				break;	
		}


	}

	render(props, state){

		let interior = props.customElementMode ? this.props.children : <slot/>;

		if( state.attributes.uses.length == 0){
			return <Fragment key="uses-fragment">
			<style id="uses-host">{`
			:host {
				transform-origin: center center;
				transform: var(--base-translate, translate(0));
			}
			`}</style>
			{interior}
			</Fragment>
		}
		
		let transformSlots= [];

		// gather transform slots used by each component
		state.attributes.uses.forEach((use, index)=>{
			if( this.usesKeys[use]){
				if( this.usesKeys[use].transformSlot ){
					transformSlots.push(this.usesKeys[use].transformSlot);
				}
			}
		});

		// and then pass them down to all components so that hard-to-combine transforms can be combined
		// see rotating-item
		state.attributes.uses.forEach((use, index)=>{
			if( this.usesKeys[use]){				
				interior = createElement( this.props.customElementMode ? this.usesKeys[use] : withScroll(this.usesKeys[use]), {
					...this.state.attributes,
					...this.props,
					lowestLevel: index==0,
					baseNode: this.props.baseNode,
					children: interior,
					transformSlots
				});

			}
		});


		let exterior = <Fragment key="uses-fragment">
		{this.props.adminMode && !props.customElementMode && <SelectionStatus baseNode={this.props.baseNode}/>}
		<style id="uses-host">{`
			:host(span){
				display: inline-block;
			}

			:host {
				position: relative;
				transform-origin: center center;
				transform: var(--base-translate, translate(0)) ${transformSlots.map(varName=>`var(${varName}, translate(0))`).join(' ')};
				${this.displayInlineBlock ? 'display: inline-block;' : ''}
			}

			${this.props.adminMode && !props.customElementMode ? `:host::selection {
				background: none;
			}` : ''}
			`}</style>
			{interior}
		</Fragment>;
		

		// if this is running directly inside a custom element, it's already part of the shadow dom
		// so render it directly to keep it from overwriting other contents
		if( props.customElementMode ){
			return exterior;
		} else {
			return createPortal(exterior, this.props.baseNode.shadowRoot)
		}

	}

	componentDidMount(){
		this.observer?.observe(this.props.baseNode, {
			attributes: true
		})
	}

	componentDidUpdate(prevProps, prevState){

		if( prevProps.uses !== this.props.uses){
			this.setState({
				attributes: this.getAttributes()
			})
		}
	}

	componentWillUnmount(){
		this.observer?.disconnect();
	}

	getAttributes = ()=>{

		const undefinedMap = {};
		for (const [key, value] of Object.entries(this.state?.attributes || {} )) {
			undefinedMap[key] = undefined;
		}

		const attributes = [...this.props.baseNode.attributes].reduce((o,a)=>{ o[a.name] = a.value; return o},{});

		for (const [key, value] of Object.entries(attributes)) {
			if( value ==='false'){
				attributes[key] = false;
			} else if ( value === 'true'){
				attributes[key] = true;	
			}
		}

		if( this.props.customElementMode ){
			
			attributes.uses = (attributes.uses || '') + ' ' + this.props.uses
		}
		attributes.uses = attributes.uses?.split(' ') || [];

		attributes.uses = _.uniq(attributes.uses);

		attributes.uses.sort((a,b)=>{
		   return this.usesOrder.indexOf(a) < this.usesOrder.indexOf(b) ? -1 : 1
		});

		return {...undefinedMap, ...attributes};

	}

	onAttributeChange = (mutationsList)=>{

		// don't let class and style changes cause a rerender
		let allowChange = mutationsList.some(mutation=>{
			return mutation.attributeName !== 'style' && mutation.attributeName !== 'class'
		})

		if( !allowChange){
			return;
		}

		const attributes = this.getAttributes();

		if( attributes.uses.length === 0 ){

			if( this.props.removeFromUseList && !this.props.customElementMode){
				this.props.removeFromUseList(this.props.baseNode);
				return;
			}

		} else {

			if(!_.isEqual(this.state.attributes, attributes)) {
				this.setState({ attributes })
			}

		}



	}
}

UsesHost.defaultProps = {
	uses: []
}


class UsesWatcher extends Component {
	constructor(props){
		super(props);

		this.mutationsList = [];
		this.hasRunFirstMutationSummary = false;		

		if(!helpers.isServer && !this.props.customElementMode) {
			this.observer = new MutationObserver(this.onMutation);
		}
		
	}

	render(){
		return this.props.children;
	}

	componentDidMount(){

		let elementsUsing = Array.from(this.props.bodycopyRef.current.querySelectorAll('[uses]'));
		elementsUsing = elementsUsing.filter(el=> !window.customElements.get(el.tagName.toLowerCase()) )

		elementsUsing.forEach(this.addToUseList);

		this.observer?.observe(this.props.bodycopyRef.current, {
			attributes: true,
			subtree: true,
			childList: true,
			attributeFilter: ['uses'],
			attributeOldValue: true,
		})
	}

	componentWillUnmount(){

		this.observer?.disconnect();

		const elementsUsing = Array.from(this.props.bodycopyRef.current.querySelectorAll('[uses]'));
		elementsUsing.forEach(this.removeFromUseList);		
	}

	onMutation = (mutationsList)=>{
		this.mutationsList.push(...mutationsList);
		cancelAnimationFrame(this.onSummaryAnimationFrame);
		if( !this.hasRunFirstMutationSummary ){
			this.onSummary();
			this.hasRunFirstMutationSummary = true;
		} else {
			this.onSummaryAnimationFrame = requestAnimationFrame(this.onSummary);
		}
	}

	onSummary = () =>{

		const mutationsList = this.mutationsList;
		let addedUse = [];
		let removedUse = [];

		mutationsList.forEach((mutation)=>{
			
			const addedNodes = Array.from(mutation.addedNodes);
			const removedNodes = Array.from(mutation.removedNodes);

			addedNodes.forEach(el=>{
				if( el.nodeType === Node.ELEMENT_NODE){
					addedUse = [...addedUse, ...Array.from(el.querySelectorAll('[uses]') ) ]					
				}
			})
			removedNodes.forEach(el=>{
				if( el.nodeType === Node.ELEMENT_NODE){
					removedUse = [...removedUse, ...Array.from(el.querySelectorAll('[uses]') ) ]					
				}
			})			

			addedUse = [...addedUse, ...addedNodes.filter(el=> el.hasAttribute?.('uses'))]
			removedUse = [...removedUse, ...removedNodes.filter(el=> el.hasAttribute?.('uses'))]

			if( mutation.type ==='attributes'){
				if( mutation.oldValue === null){
					addedUse.push(mutation.target);
				} else if ( mutation.target.getAttribute('uses') === null ) {
					removedUse.push(mutation.target);
				}
			}

		})

		addedUse = _.uniq(addedUse);
		removedUse = _.uniq(removedUse);

		// don't allow custom elements to use this method - they will hook into `uses` on their own
		addedUse = addedUse.filter(el=> !window.customElements.get(el.tagName.toLowerCase()) )
		removedUse = removedUse.filter(el=> !window.customElements.get(el.tagName.toLowerCase()) )

		// make sure elements that were added/removed didn't get those actions reversed in the same frame
		addedUse = addedUse.filter(el=>el.hasAttribute('uses'))
		removedUse = removedUse.filter(el=>!el.hasAttribute('uses'))

		addedUse.forEach(this.addToUseList)
		removedUse.forEach(this.removeFromUseList)

		this.mutationsList = []

	}

	addToUseList = (el)=>{
		if( el.nodeType !== Node.ELEMENT_NODE){
			return;
		}

		if( !el.shadowRoot){
			try {
				el.attachShadow({mode: 'open'})
			} catch (error) {

				CargoEditor?.mutationManager?.execute(()=>{
					const attrs = {};
					if ( el.hasAttribute('uses') ) {
						attrs.uses = el.getAttribute('uses');
					}
					if ( el.hasAttribute('rotation') ) {
						attrs.rotation = el.getAttribute('rotation');
					}
					if ( el.hasAttribute('animate') ) {
						attrs.animate = el.getAttribute('animate');
					}
					if ( el.hasAttribute('scroll-transition') ) {
						attrs['scroll-transition'] = el.getAttribute('scroll-transition');
					}

					// create and insert a valid shadow dom host span
					const wrap = document.createElement('span');

					for(const key in attrs){
						wrap.setAttribute(key, attrs[key]);
					}

					el.parentNode.insertBefore(wrap, el);

					const range = CargoEditor?.getActiveRange();

					// move in the element whilst preserving the range
					CargoEditor.helpers.movePreservingRanges(el, wrap, -1, range);

					// create shadow dom and clean up node
					wrap.attachShadow({mode: 'open'})
					el.removeAttribute('scroll-transition');
					el.removeAttribute('uses');
					el.removeAttribute('animate');
					el.removeAttribute('rotation');

					el = wrap;

				});
				return;
			}
		}


		if (!useList.has(el)){
			useList.add(el);
		}

		const event = {
			component: <UsesHost 
				customElementMode={false}
				baseNode={el}

				adminMode={this.props.adminMode}
				removeFromUseList={this.removeFromUseList}
			/>,
			element: el,
			portalHost:null
		}

		dispatch(el, 'custom-element-connected', event);

		this.portalHost = event.portalHost

	}

	removeFromUseList = el => {


		dispatch(el, 'custom-element-disconnected', {
			component: null,
			element: el,
			portalHost: this.portalHost
		});

		// it's not possible to remove a shadow dom once it's in place, so instead we steamroll it by rewriting the element
		// only non-custom-elements should be inside this list
		if (useList.has(el)){

			useList.delete(el);

			if( el.shadowRoot ){

				if( document.body.contains(el) ){

					if( CargoEditor?.mutationManager ){
						CargoEditor?.mutationManager?.execute(()=>{
							const div = document.createElement('div');
							div.innerHTML = el.outerHTML;

							el.replaceWith(div.childNodes[0]);
						});

					} else {

						const div = document.createElement('div');
						div.innerHTML = el.outerHTML;
						el.replaceWith(div.childNodes[0]);
					}

				} else {

				}
			}

		}
	}

}

export default UsesWatcher
export {UsesHost}
