import { Component, toChildArray, createRef} from "preact";
import { PureComponent, createPortal } from "preact/compat";
import { diffProps } from 'node_modules!preact/src/diff/props.js'

import { forwardRef } from 'preact/compat';
import ResizeCard from "../resize-card";
import { connect } from 'react-redux';
import withThumbnailContent from "../thumbnail-index-generator";

import { helpers } from "@cargo/common";
import register from "../../register";
import JustifyEditor from '../../../overlay/justify-editor';
import windowInfo from "../../../window-info"

import { withPageInfo } from "../../page-info-context";
import _ from 'lodash';


class Justify extends PureComponent {

	constructor(props){
		super(props);

		this.state = {
			isMobile: windowInfo.data.mobile.active,
			elWidth: 0,
			gutterPixelWidth: 0,
			gutterHeight: 0,
			targetPxHeight: 0,
			sizeIncrement: 0,
			hasSizes:false,
			renderLimit: 150
		}
		
		this.paginationWatcherRef = createRef();

		this.paginationObserver = new IntersectionObserver(this.requestPagination, {
			root: this.props.scrollContext.scrollingElement === window ? document : this.props.scrollContext.scrollingElement,
			rootMargin: (screen.height * 2) + 'px',
			threshold: [0,1]
		});

	}

	requestPagination = async entries => {

		// store latest intersection result
		this.isIntersecting = entries[0].isIntersecting;

		if(
			// do nothing if a pagination loop is already running
			this.isPaginating
			// or if we already hit the limit
			|| this.state.renderLimit >= this.getMediaItems().length
		) {
			return;
		}

		this.isPaginating = true;

		// keep going while the observer is within the pagination boundary
		while(this.isIntersecting) {

			// increase render limit by 150 items
			let newRenderLimit = this.state.renderLimit + 150;

			this.setState({
				renderLimit: newRenderLimit
			});

			if(newRenderLimit >= this.getMediaItems().length) {
				// we've reached the limit. Don't do anything
				break;
			}

			// wait a beat before continuing
			await new Promise(resolve => setTimeout(resolve, 200));

		}

		// The pagination loop has completed and we are 
		// ready to start a new one if needed.
		this.isPaginating = false;

	}
	
	getMediaItems() {
		return Array.from(this.props.baseNode.children).filter(node => node.nodeName =='MEDIA-ITEM');
	}

	render(props, state){
		const {
			gutterPixelWidth,
			gutterHeight,
			targetPxHeight,
			elHeight,
			isMobile,
			hasSizes
		} = state;

		const {
			children,
			adminMode,
			pageInfo,
			baseNode,			
		} = props;

		let {
			elWidth
		} = state;

		if( !hasSizes ){
			elWidth = this.props.baseNode.offsetWidth;
		}



		let {			
			'row-align': rowAlign,
			'row-height': height,
			gutter,
		} = props;

		gutter = (isMobile && this.props['mobile-gutter'] !== undefined ) ? this.props['mobile-gutter'] : this.props.gutter;

		let mobileRowAlignPrefix = '';
		if(isMobile && this.props['mobile-row-align'] !== undefined ){
			rowAlign = this.props['mobile-row-align'];
			mobileRowAlignPrefix = 'mobile-';
		}
		if(isMobile && this.props['mobile-row-height'] !== undefined ){
			height = this.props['mobile-row-height'];
		}

		let gutterArr = gutter.split(' ');

		let horizontalGutter = gutterArr.length > 1 ? gutterArr[0] : gutterArr[0];
		if ( horizontalGutter.includes('rem') && isMobile ) {
			horizontalGutter = 'calc( var(--mobile-padding-offset, 1) * ' + horizontalGutter + ' )';
		}

		let verticalGutter = gutterArr.length > 1 ? gutterArr[1] : gutterArr[0];
		if ( verticalGutter.includes('rem') && isMobile ) {
			verticalGutter = 'calc( var(--mobile-padding-offset, 1) * ' + verticalGutter + ' )';
		}

		let rowHeight = helpers.getCSSValueAndUnit(height, '%').join('');

		const mediaItems = this.getMediaItems();
		const renderedMediaItems = mediaItems.slice(0, this.state.renderLimit);

		const layout = this.brickLayout(renderedMediaItems);

		const shadowStyleMap = [];

		mediaItems.forEach((el, index)=>{
			
			const attribs = layout.attributes[index] || {
				slot: null
			}
			const props = el._props || {}

			diffProps(el, {...props, ...attribs}, props, false, false)
		});

		return <>
			<>{this.props.children}</>
			{createPortal(
			<>
					<style>{`
* {
	box-sizing: border-box;
}

:host {
	width: var(--resize-parent-width, 100%);
	max-width: 100%;	
	position: relative;
	display: block;
	--gutter-width: ${gutterPixelWidth}px;
	--gutter-height: ${gutterHeight}px;
	margin-top: calc(var(--gutter-expand, 0) * ${gutterHeight}px );	
}

${layout.shadowStyleMap.map((mapItem, index)=>{
	return `::slotted(media-item[slot="slot-${index}"]){
		${mapItem.style};
		${mapItem.itemStatuses.lastRow ? '' : 'margin-bottom: var(--gutter-height);'}
		${mapItem.itemStatuses.lastChild ? '' : 'margin-right: var(--gutter-width);'}
		${mapItem.itemStatuses.lastChild && !mapItem.itemStatuses.orphanRow && rowAlign ==='fill' ? `
			flex-grow: 1;
			--object-fit: fill;
		` : ''}
	}`
}).join('')}

:host .justify-row {
	max-width: ${elWidth+3}px;
	width: 100%;
	display:flex;
	flex-shrink: 0;
	align-items: stretch;
	justify-content: flex-start;
	position: relative;
	flex-wrap: nowrap;
}

:host .justify-row.orphan-row {
	width: ${layout.orphanRowWidth}px;
	display: inline-flex;
}

::slotted(media-item) {
	position: relative;
	max-width: 100%;
	display: flex;
	flex-shrink: 0;
	flex-grow: 0;
}    


:host([${mobileRowAlignPrefix}row-align="left"]) .justify-row {
	justify-content: flex-start;
}

:host([${mobileRowAlignPrefix}row-align="right"]) .justify-row {
	justify-content: flex-end;
}

:host([${mobileRowAlignPrefix}row-align="center"]) .justify-row {
	justify-content: center;
}

:host ::slotted(media-item.last-child), :host([${mobileRowAlignPrefix}row-align="fill"]) ::slotted(media-item.last-child) {
	flex-grow: 1;
}

:host([${mobileRowAlignPrefix}row-align="center"]) ::slotted(media-item.last-child),
:host([${mobileRowAlignPrefix}row-align="left"]) ::slotted(media-item.last-child),
:host([${mobileRowAlignPrefix}row-align="right"]) ::slotted(media-item.last-child) {
	flex-grow: 0;
}

					`}</style>
				
					<ResizeCard
						values={{
							targetPxHeight: rowHeight,
							gutterPixelWidth: horizontalGutter,
							gutterHeight: verticalGutter,
							elWidth: '100%',
						}}
						onResize={this.onResize}
					/>
					{layout.shadow}
					{adminMode && pageInfo.isEditing && !helpers.isServer && <JustifyEditor
							{...props}
							gallerySpecificAttributes={['justify-row-end']}
							galleryInstance={baseNode}
							gutterWidth={gutterPixelWidth}
							gutterHeight={gutterHeight}
							elWidth={elWidth}
							rowHeight={rowHeight}
							rowAlign={rowAlign}
							mediaItems={mediaItems}
							incrementLayout={this.onItemResize}
						/>
					}
					<div ref={this.paginationWatcherRef} class='pagination-watcher'></div>
			</>, this.props.baseNode.shadowRoot)}
		</>
	}

	onResize = (key, value) =>{
		if( key === 'targetPxHeight'){
			value = Math.max(value, 10)
		}
		this.setState({
			[key]: value,
			hasSizes: true,			
		});

	}

	onMobileChange = (isMobile)=>{
		this.setState({
			isMobile
		})
	}

	componentDidMount(){
		windowInfo.on('mobile-change', this.onMobileChange)

		this.props.baseNode._figureEditorInfo = {
			disabledMediaItemOptions: layoutData.disabledMediaItemOptions,
			galleryParent: this.props.baseNode,
			galleryWidth: this.state.elWidth
		}

		if(this.paginationObserver && this.paginationWatcherRef.current) {
			this.paginationObserver.observe(this.paginationWatcherRef.current);
		}

		if(this.props.onMount){
			this.props.onMount();
		}
	}
	componentWillUnmount(){

		windowInfo.off('mobile-change', this.onMobileChange);
		
		if(this.paginationObserver && this.paginationWatcherRef.current) {
			this.paginationObserver.unobserve(this.paginationWatcherRef.current);
		}

	}

	onItemResize = (element)=>{
		this.setState(prevState=>{
			return {
				sizeIncrement: prevState.sizeIncrement+1,
			}
		})
	}


	brickLayout = (mediaItems) => {

		const resolutionMultiplier = window.devicePixelRatio > 1 ? 2 : 1

		const {
			isMobile
		}= this.state;
		let {
			'row-align': rowAlign
		} = this.props;


		if(isMobile && this.props['mobile-row-align'] !== undefined ){
			rowAlign = this.props['mobile-row-align'];
		}
		
		const map = {};
	
		const isUsingMobileRowHeight = isMobile && this.props['mobile-row-height'] !== undefined && this.props['mobile-row-height'] !== this.props['row-height'];

		let inDesignatedRow = false;

		const childDimensionArray = mediaItems.map((mI, i)=>{
			const entry = this.getItemSize(mI, isUsingMobileRowHeight);
		
			return this.getItemSize(mI, isUsingMobileRowHeight);
		});

		for( let i = childDimensionArray.length-1; i>= 0; i--){
			const entry = childDimensionArray[i];
			if( entry.rowEnd || inDesignatedRow){
				entry.inDesignatedRow = true;
				inDesignatedRow = true;
			} else {
				entry.inDesignatedRow = false;
			}		
		}

		this.findValidRowCache = {};
		this.makeNodes(0, 0, null, map, childDimensionArray);
		this.findValidRowCache = null;

		let finalMap = [];
		const mapKeys = _.keys(map).sort(function(a, b) {
		  return parseInt(a) - parseInt(b);
		});

		// start with final key, and cycle through parents to create final map
		let lastKey = mapKeys[mapKeys.length-1];
		while (lastKey != null){
			const node = map[lastKey];

			if ( map[node.parent] ){
				map[node.parent].path = map[node.parent].edges[lastKey];			
			}

			// if(node.path){
				finalMap.unshift(node)
			// }

			lastKey = node.parent;			
		}

		const shadow = [];
		const attributes = [];
		const shadowStyleMap  = [];

		// const itemPad = this.state.itemSize.padSize+this.state.itemSize.mediaPadSize;
		const targetPxHeight = this.state.targetPxHeight;
		const elWidth = this.state.elWidth;
		const gutterWidth = this.state.gutterPixelWidth;

		let orphanRowWidth = 0;

		finalMap.forEach((brickRow, index)=>{

			const path = brickRow.path
			const slots = [];
			if (!path){
				return;
			}

			let rowPxHeight = 0;
			let orphanRow = false;

			for(let i = path.startIndex; i < path.breakBefore; i++ ){
				const el = mediaItems[i];

				const rowLength = path.breakBefore - path.startIndex;
				const lastInRow = (i+1 == path.breakBefore);

				const itemDimensions = this.getItemSize(el, false);

				const itemPad = itemDimensions.mediaSize.padSize + itemDimensions.mediaItemSize.padSize;
				const scaleDown = (targetPxHeight+-itemPad)/itemDimensions.height || 1;

				let newWidth;
				let newHeight;

				newWidth = (itemDimensions.width*scaleDown)+itemPad ;
				newHeight = itemDimensions.height*scaleDown+itemDimensions.mediaSize.padSize;

				// width integrates media item and media pad
				// height only integrates media pad

				// itemDimensions.width = itemDimensions.width * scaleDown;
				// itemDimensions.height = itemDimensions.height * scaleDown;
				let remainder = elWidth +- path.rowWidth;

				if( rowAlign !== 'fill' || orphanRow ){
					remainder = Math.min(0, remainder)
					orphanRowWidth = path.rowWidth					

				// if the item has to expand above twice its width just to fill out a single row, zero-out the remainder
				// IF it's not a designated row
				} else if ( remainder / elWidth > .5  && lastInRow && index == 0 && !path.position =='forcebreak'){
					
					orphanRow = true;
					i = path.startIndex-1;
					continue
				}

				// if we have to scale the row up or down....
				if (remainder !== 0){

					// remainder is the amount of space left to fill
					// path.rowWidth is the width of the row as laid-out
					// path.contentWidth is the width of the row minus the gutters and padding

					/*
						first we compare the width of the item (no padding or gutters) to the overall width of the content in the row
						if the width is 20% of the total row-content at the base size, it should stay the same proprtion when scaled up
					*/
					const newWidthNoPad = newWidth+-itemPad;					
					const proportionOfWidth = newWidthNoPad/(path.contentWidth);	

					/*
						we just need to find out the size of the scaled content , which would be the element width
						minus all accumulated padding + gutters.
					*/
					const accumulatedPaddingAndGutters = path.rowWidth - path.contentWidth;
					const scaledContentWidth = elWidth - accumulatedPaddingAndGutters


					newWidth = proportionOfWidth*scaledContentWidth;
					newHeight = newWidth* ( itemDimensions.height/itemDimensions.width) + itemDimensions.mediaSize.padSize
					newWidth = newWidth+itemPad;

				}

				// make sure widths are rounded to correct sisze
				newWidth = Math.floor(newWidth * resolutionMultiplier ) / resolutionMultiplier;							

				// row px height is rounded later in the function
				rowPxHeight = newHeight;

				const itemStatuses = {

				}
				let classNames = '';
				if(i == path.startIndex){
					itemStatuses.firstChild = true;
					// classNames+=' first-child'
				}
				if(i+1 == path.breakBefore){
					itemStatuses.lastChild = true;
					// classNames+=' last-child'	
				}

				if(index+2 == finalMap.length){
					itemStatuses.lastRow = true;
					// classNames+=' last-row'	
				}

				itemStatuses.orphanRow = orphanRow;




				// node.attribs['data-show-caption'] = false

				shadowStyleMap[i] = {
					style: `width: ${newWidth}px`,
					itemStatuses
				}

				 attributes[i] = {
					slot: `slot-${i}`,
					// style: {
					// 	'width': `${newWidth + itemPad}px`,
					// },
					// className: classNames,
					itemResize: this.onItemResize
				 };

// 	   		 	 	disabledAttributes={['data-scale', 'data-sticker']}
// 	   		 	 		'data-justify-break-after': itemDimensions.rowEnd


				slots[i] =<slot
					key={`slot-${i}`}
					name={`slot-${i}`} />

			}


			rowPxHeight = Math.floor(rowPxHeight * resolutionMultiplier ) / resolutionMultiplier;			

			shadow.push(<div
				key={`justify-row-${index}`}
				className={`justify-row${orphanRow ? ' orphan-row' :''}`}
				style={{
					'--frame-padding':rowPxHeight+'px' 
				}}
				>
				{slots}
			</div>);

		});

		return {shadow, attributes, shadowStyleMap, orphanRowWidth};

	}


	findValidRows = (startIndex, childDimensionArray)=> {

		if(this.findValidRowCache[startIndex]) {
			//console.log('cache hit')
			return this.findValidRowCache[startIndex]
		}

		startIndex = parseInt(startIndex);
		let breakIndex = startIndex;
		let rowWidth = 0;
		let contentWidth = 0;
		let scaledWidth = 0;
		let scaledWidthNoItemPad = 0;
		let items = 0;
		let validRows = {};
		let rowAlign = this.props['row-align'];

		if(this.state.isMobile && this.props['mobile-row-align'] !== undefined ){
			rowAlign = this.props['mobile-row-align'];
		}

		for(let i = startIndex; i < childDimensionArray.length; i++){

			const lastScaledWidth = scaledWidth;
			const lastScaledWidthNoItemPad = scaledWidthNoItemPad;

			const itemDimensions = childDimensionArray[i];
			const itemPad = itemDimensions.mediaSize.padSize + itemDimensions.mediaItemSize.padSize;
			const scale = (this.state.targetPxHeight+-itemPad)/itemDimensions.height;

			scaledWidthNoItemPad = itemDimensions.width * scale;
			scaledWidth = scaledWidthNoItemPad + itemPad;

			// beginning of row
			if ( i === startIndex){
				items = 1;
				rowWidth = scaledWidth;
				contentWidth = scaledWidth+-itemPad;
			} else {
				rowWidth = rowWidth + this.state.gutterPixelWidth + scaledWidth;
				contentWidth = contentWidth + scaledWidthNoItemPad;
				items++;
			}

			// if next is new line
			if (itemDimensions.rowEnd){

				//console.log('a', i);

				validRows[i+1] = {
					position: 'forcebreak',
					penalty: -9e9,
					itemCount: items,
					startIndex: startIndex,
					breakBefore: i+1,
					rowWidth: rowWidth,
					contentWidth: contentWidth		
				}

				break;

			} else if ( rowWidth  > this.state.elWidth) {

				if ( itemDimensions.inDesignatedRow ){
					continue
				}
				//console.log('b', i);
				
				validRows[i] = {
					position: 'under',
					itemCount: items+-1,
					penalty: 0,
					startIndex: startIndex,
					breakBefore: i,
					rowWidth: (rowWidth+-(this.state.gutterPixelWidth + scaledWidth)),
					contentWidth: (contentWidth-scaledWidthNoItemPad)
				}
			
				if( rowAlign ==='fill' || i === startIndex){
				//console.log('c', i);
				
					// and then also hit the 'over'
					validRows[i+1] = {
						position: 'over',
						penalty: 0,
						itemCount: items,
						startIndex: startIndex,
						breakBefore: i+1,
						rowWidth: rowWidth,
						contentWidth: contentWidth						
					}

				} else if (i-1 > -1) {
				//console.log('d', i);
				
					validRows[i-1] = {
						position: 'oneunder',
						penalty: .11,
						itemCount: items+-2,
						startIndex: startIndex,
						breakBefore: i-1,
						rowWidth: (rowWidth+-(this.state.gutterPixelWidth*2 + scaledWidth +lastScaledWidth )),
						contentWidth: (contentWidth-(scaledWidthNoItemPad+lastScaledWidthNoItemPad))					
					}
				}
				break;

			} else if(i == childDimensionArray.length-1){
				//console.log('e', i);
				
				if( rowAlign !=='fill' && i== startIndex){
					validRows[i+1] = {
						position: 'orphan',
						penalty: 0.5,
						itemCount: items,
						startIndex: startIndex,
						breakBefore: i+1,
						rowWidth: rowWidth,
						contentWidth: contentWidth						
					}	
				} else {
				//console.log('f', i);
				
					// if it's the last, then we cut things off
					// with a slight penalty, because it's not the first choice		
					validRows[i+1] = {
						position: 'last',
						penalty: 0.06,
						itemCount: items,
						startIndex: startIndex,
						breakBefore: i+1,
						rowWidth: rowWidth,
						contentWidth: contentWidth
					}
				}

				break;

			} else if ( items+-2 > 0 && !itemDimensions.inDesignatedRow && rowAlign ==='fill'){
				//console.log('g', i);
				

				// capture something in the middle of the row, just in case				
				validRows['mid'] = {
					position: 'mid',
					penalty: 0.06,
					itemCount: items+-2,
					startIndex: startIndex,
					breakBefore: i-1,
					rowWidth: (rowWidth+-(this.state.gutterPixelWidth*2 + scaledWidth +lastScaledWidth )),
					contentWidth: (contentWidth-(scaledWidthNoItemPad+lastScaledWidthNoItemPad))					
				}
				
			}

		}

		/*
		collate special case into the valid rows object. this is mostly
		useful for galleries with relatively few items, where it would be
		more desirable to have two equal rows rather than one tiny and one
		huge row
		*/

		if (validRows.hasOwnProperty('mid')){
			let midIndex = validRows.mid.breakBefore;
			if ( !validRows.hasOwnProperty(midIndex) && childDimensionArray.length < 15){
				validRows[validRows.mid.breakBefore] = validRows.mid;
			}
			delete validRows.mid
		}

/*
calculate row penalty, which is calculated as a deviation from ideal, where the
ideal row width fits perfectly into element width. penalties are added here.
*/

		for (const key in validRows) {

			const row = validRows[key];
			let scale = row.rowWidth / this.state.elWidth;

			row.distance = Math.abs(1-scale)*Math.abs(1-scale)*Math.abs(1-scale);
			if ( !isNaN(row.penalty) ){
				row.distance = row.distance+row.penalty
			}

		}

		// store for later use
		this.findValidRowCache[startIndex] = validRows;

		return validRows;
	}

	/* transforms map */

	makeNodes = (index, distanceToFirstNode, parent, map, childDimensionArray) => {
		if (index === -1){
			return;
		}

		const node = {
			index: parseInt(index),
			edges: this.findValidRows(index, childDimensionArray),
			distanceToFirstNode: distanceToFirstNode,
			parent: parent
		}

		let nodeAdded = false;

		if ( map[index]){

			if ( node.distanceToFirstNode < map[index].distanceToFirstNode){
				map[index] = node;
				nodeAdded = true;
			}
			
		} else {
			map[index] = node;
			nodeAdded = true;
		}

		if (nodeAdded){
			let rowKeys = _.keys(node.edges);
			for( let i = 0; i < rowKeys.length; i++){

				const row = node.edges[rowKeys[i]]
				this.makeNodes(parseInt(rowKeys[i]), row.distance+node.distanceToFirstNode, node.index, map, childDimensionArray)
			}
		}

	}


	getItemSize = (mediaItem, isUsingMobileRowHeight)=> {

		const size = mediaItem._size || {
			width: 0,
			height: 0,
			mediaSize: {
				width: 0,
				height: 0,
				padSize: 0,
			},
			mediaItemSize: {
				width: 0,
				height: 0,
				padSize: 0,
			}					
		};

		if( isNaN(size.width) ){
			size.width = 300
		}
		if( isNaN(size.height) ){
			size.height = 300
		}

		let rowEnd = (
			mediaItem.getAttribute('justify-row-end') === 'true' 
		) && !isUsingMobileRowHeight ;		

		return {
			...size,
			rowEnd,
		}		
	}

}

const layoutData = {
	name: 'justify',
	displayName: 'Justify',
	tagName: 'GALLERY-JUSTIFY',
	disabledMediaItemOptions:['scale', 'limit-by'],	
	mediaItemOptions: [
		{
			labelName: "End Row Here",
			name: "justify-row-end",
			type: "check-box",
			value: true,
		},		
	],
	options: [
		{
			labelName: "Row Height",
			name: "row-height",
			type: "scrubber",
			addDefaultUnitToUnitlessNumber: true,
			allowCalc: true,
			allowVar: true,
			value: '20%',
			min: 1,
			defaultUnit: '%',
			step: {px: 1, rem: 0.1, em: 0.1, '%': 0.1, vw: 0.1, vmin: 0.1, vmax: 0.1, vh: 0.1, etc: 1},
			allowedUnits: ['px','%','rem','vmin','vmax','vw','vh', 'em']
		},
		{
			labelName: "Gutter",
			name: "gutter",
			type: "composite-scrubber",
			addDefaultUnitToUnitlessNumber: true,
			value: '2rem',
			defaultUnit: 'rem',
			min: 0,
			max: {
				vw: 30,
				vh: 30,
				vmax: 30,
				vmin: 30,
				rem: 20,
				em: 20,
				px: 300,
				etc: 100,
			},
			step: {px: 1, rem: 0.1, em: 0.1, '%': 0.1, vw: 0.1, vmin: 0.1, vmax: 0.1, vh: 0.1, etc: 1},
			allowedUnits: ['px', '%', 'rem', 'vmin', 'vmax', 'vw', 'vh'],
		},
		{
			labelName: "Row Align",
			name: "row-align",
			type: "radio",
			value: "fill",
			values: [
				{
					labelName: 'Left',
					iconName: 'alignContentLeft',
					value: 'left'
				},
				{
					labelName: 'Center',
					iconName: 'alignContentCenter',
					value: 'center'
				},
				{
					labelName: 'Right',
					iconName: 'alignContentRight',
					value: 'right'
				},
				{
					labelName: 'Fill',
					iconName: 'alignContentFill',					
					value: 'fill'
				},				
			],
		},
		{
			type: 'more-actions',
			children: [
				{
					labelName: "Clear Designated Rows",
					name: "clear-designated-rows",
					type: "button",
					className: 'text-button remove',
					onClick: function(e){
						e.preventDefault();
						e.stopPropagation();
						const hardSetRowItems = Array.from(this.state.activeGallery.children).filter(node=>node.hasAttribute('justify-row-end') || node.hasAttribute('justify-row-start'));
						CargoEditor.mutationManager.execute(()=>{
							hardSetRowItems.forEach(el=>{
								el.removeAttribute('justify-row-end')
							});	
						});
						CargoEditor.events.trigger('cursor-activity', CargoEditor.getActiveEditor() );
					}
				}				
			]
		},	
	],
	mobileOptions: [
		{
			labelName: "Row Height",
			name: "mobile-row-height",
			type: "scrubber",
			addDefaultUnitToUnitlessNumber: true,
			allowCalc: true,
			allowVar: true,
			value: '20%',
			min: 1,
			defaultUnit: '%',		 	
			allowedUnits: ['px','%','rem','vmin','vmax','vw','vh', 'em']
		},
		{
			labelName: "Gutter",
			name: "mobile-gutter",
			type: "composite-scrubber",
			addDefaultUnitToUnitlessNumber: true,
			value: '2rem',
			defaultUnit: 'rem',
			min: 0,
			max: {
				rem: 15,
				em: 20,
				px: 100,
				etc: 10,
			},
			allowedUnits: ['px', '%', 'rem', 'vmin', 'vmax', 'vw', 'vh'],
		},
		{
			labelName: "Row Align",
			name: "mobile-row-align",
			type: "radio",
			value: "fill",
			values: [
				{
					labelName: 'Left',
					iconName: 'alignContentLeft',
					value: 'left'
				},
				{
					labelName: 'Center',
					iconName: 'alignContentCenter',
					value: 'center'
				},
				{
					labelName: 'Right',
					iconName: 'alignContentRight',
					value: 'right'
				},
				{
					labelName: 'Fill',
					iconName: 'alignContentFill',					
					value: 'fill'
				},				
			],
		}
	]	
};

layoutData.mediaItemDefaults = helpers.collapseOptions(layoutData.mediaItemOptions);
layoutData.defaults = helpers.collapseOptions(layoutData.options);
layoutData.mobileDefaults = helpers.collapseOptions(layoutData.mobileOptions);


Justify.defaultProps = {
	'show-tags': true,
	'show-title': true,	

	'gutter': layoutData.defaults['gutter'].value,		
	'row-align': layoutData.defaults['row-align'].value,
	'row-height': layoutData.defaults['row-height'].value,	

	'mobile-gutter': undefined,
	'mobile-row-height': undefined,
	'mobile-row-align': undefined,
}


const ConnectedJustify = withPageInfo(withThumbnailContent(connect(
	(state, ownProps) => {
		return {
			adminMode: state.frontendState.adminMode
		};
	}
)(Justify)))

register(ConnectedJustify, 'gallery-justify', [
	'thumbnail-index',
	'thumbnail-index-metadata',
	'links-filter-index',
	'show-tags',
	'show-title',

	'row-align',
	'row-height',
	'gutter',
	'mobile-gutter',
	'mobile-row-height',
	'mobile-row-align'
], {
	renderIntoShadow: true
}) 

export {layoutData};
export default ConnectedJustify



