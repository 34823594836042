import { C2_API_ORIGIN } from "@cargo/common";
import React, { Component, useState, useEffect } from 'react';

export const DomainRow = (props) => {

	const { domain, tld, chooseDomain, index } = props;

	const [domainInfo, setdomainInfo ] = useState(null);

	useEffect(() => {

		fetch(`${C2_API_ORIGIN}/v0/domains/availability/${domain}/${tld}`).then(async response => {
			const data = await response.json();
			setdomainInfo( data )
		}).catch(e => {
			console.error(e);
		})

	}, []);

	// generate a quasi-unique ID to link label and input.
	return (
		<div 
			className={`single-domain${domainInfo?.available ? ( ' available' ) : (' unavailable')}`} 
			data-tld={ tld }
			onMouseDown={()=> {
				if( !domainInfo?.available ){ return }
				chooseDomain( domainInfo, tld );
			}}
		>
			<div className="status">
				<div className={`available${domainInfo?.available ? (' yes'): (' no')}${ !domainInfo ? (' pending'): ('')}`}></div>
			</div>
			<div className="domain-text">
				<span className="url">{domain}</span>
				<span>.{tld}</span>
			</div>
			<div className="availability-text">{ domainInfo ? domainInfo?.available ? ( `$${domainInfo?.cost}` ) : ('Taken') : ('') }</div>
		</div>
	)
	
};