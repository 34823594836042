import { Component , createRef, Fragment } from "preact";
import { createPortal } from 'preact/compat';
import { ADMIN_FRAME } from "../../globals";
import { helpers } from "@cargo/common";
import { editorOverlayAPI } from "./editor-overlay-controller"
import SelectionStatus from "./selection-status";
import windowInfo from '../window-info';

import _ from 'lodash';

let mediaItemWithMultipleButton = null;
let mediaItemEditors = new Set();
let mediaItemMultiSelect = _.debounce(()=>{
	let editorArray = Array.from(mediaItemEditors);
	let sortedArray = [];
	const multiSelect = editorArray.filter(component=>component.state.selected).length > 1;

	let multiSelectHeight = 0;
	if( multiSelect){

		const rectArray = editorArray.map((component)=>{

			let rect
			if( component.state.selected){
				rect = component.props.baseNode.getBoundingClientRect();	
			} else{
				rect = {
					top: -9e9,
				}
			}
			
			return {
				component,
				rect
			};
		});

		sortedArray = _.sortBy(rectArray, (obj, index)=>{
			return obj.rect.top
		})

		const selectedSorted = sortedArray.filter(obj=>{
			return obj.component.state.selected
		})

		const lastItemSize = selectedSorted[selectedSorted.length-1].component.props.baseNode._size;
		multiSelectHeight = selectedSorted[selectedSorted.length-1].rect.bottom + -selectedSorted[0].rect.top +- lastItemSize.mediaItemSize.padSize + -lastItemSize.mediaSize.padSize;

		// return it back into an array of components
		sortedArray = sortedArray.map(obj=>{
			return obj.component
		})
	} else {
		sortedArray = editorArray;
	}

	let selectionLeaderFound = false;
	sortedArray.forEach(component=>{

		if( !multiSelect || !component.state.selected ){
			component.setState({
				pointerProxyAttention: false,
				partOfMultipleSelection: false,
				showMultipleSelectionButton: false,
				multiSelectHeight: null
			})
		} else {

			if( !selectionLeaderFound ){
				component.setState({
					pointerProxyAttention: true,
					partOfMultipleSelection: true,
					showMultipleSelectionButton: true,
					multiSelectHeight
				}, ()=>{
					component.setEditorFrame();
				})
				selectionLeaderFound = true;
			} else {
				component.setState({
					partOfMultipleSelection: true,
					showMultipleSelectionButton: false,
					multiSelectHeight: null
				})					
			}

		}

	})

		
}, 100);

if(!helpers.isServer) {

	if( CargoEditor && Object.keys(CargoEditor || {}).length > 0 ){
		CargoEditor.events.on('cursor-activity', mediaItemMultiSelect)
	} else {
		window.addEventListener('CargoEditor-load', function(){
			CargoEditor.events.on('cursor-activity', mediaItemMultiSelect)
		});
	}	

	document.addEventListener('selectionchange', mediaItemMultiSelect);
}

class MediaItemEditor extends Component {
	constructor(props){
		super(props);
		this.state = {
			selected: false,
			partOfMultipleSelection: false,
			showMultipleSelectionButton: false,

			overlayPosition: {
				x: 0,
				y: 0,
			},

			mediaItemEditorInfo: {},
			resizeCorner: 0, // index of corner the resize button is in, relative to mediaCorner

			pointerAttention: false,
			pointerProxyAttention: false,

			activeGallery: null,
			disabledFigureOptions: [],
			editorFramePosition: {x: 0, y: 0},
			editorMediaFramePosition: {x: 0, y: 0},

			UIWindow: false,

			cursorOrientation: 'nwse-resize',
			isResizing: false,
			dragStartPosition: {x: 0, y: 0},
			dragDelta: {x: 0, y: 0},
			scalingLimit: 1000,

			resizeDimensions: {
				scale: '100%',
				scaleArray: [100,'%'],
				newWidth: 'auto',
				newHeight: 'auto',
				width: 0,
				height: 0,
				displayScale: null,
			},

			initialDimensions: {
				scale: '100%',
				scaleArray: [100,'%'],
				newWidth: 'auto',
				newHeight: 'auto',
				width: 0,
				height: 0,
				displayScale: null,
			},

			handlePos: {
				x: 100,
				y: 100,
				w: 100,
				h: 100
			},
			contextPos: {
				x: 100,
				y: 100,
				w: 100,
				h: 100
			},

			mediaCornerPositions: [
				{x: 0, y: 0},
				{x: 0, y: 0},
				{x: 0, y: 0},
				{x: 0, y: 0},
			],

			multiSelectHeight: null

		}

		this.renderElement = editorOverlayAPI.getPortal(this.props.baseNode, {
			noPointerEvents: false,
			trackResize: false,
			extraMargins: false,
			component: this,			
		});
		this.mediaGhostRef = createRef();

		
		this.observer = new MutationObserver(this.checkForEmptyCaption);		

	}

	render(props,state){


		 const {
		 	mediaItemEditorInfo,
		 	overlayPosition,
			resizeCorner,
			pointerAttention,
			pointerProxyAttention,
			disabledFigureOptions,
			editorFramePosition,
			editorMediaFramePosition,
			isResizing,
			dragStartPosition,
			dragDelta,
			scalingLimit,
			resizeDimensions,
			handlePos,
			mediaCornerPositions,
			cursorOrientation,
			partOfMultipleSelection,
			showMultipleSelectionButton,
			UIWindow,

			contextPos,
		} = state;

		const {
			rotation,
			dimensions,
			mediaDimensions,
			baseNode,
			size,
		} = props;

		if( mediaItemEditorInfo?.disableAll == true){
			return null;
		}


		let scaleX = (resizeDimensions.width*resizeDimensions.overSize)/size.mediaSize.width;
		let scaleY = (resizeDimensions.height*resizeDimensions.overSize)/size.mediaSize.height;
		let resizeBorderScale = 1/scaleX || 1;


		let displayScale = props.scale
		if( isResizing ){
			if( resizeDimensions.displayScale){

				displayScale = resizeDimensions.displayScale;

			} else if( resizeDimensions.scaleArray[1] == 'px'){

				displayScale = parseFloat(resizeDimensions.scaleArray[0]).toFixed(0)+resizeDimensions.scaleArray[1]+''

			} else {
				displayScale = parseFloat(resizeDimensions.scaleArray[0]).toFixed(1)+resizeDimensions.scaleArray[1]+''
			}

		}
		displayScale = displayScale === '100.0%' ? '100%' : displayScale;

		let currentRotation = props.rotation+'°';

		// defining buttons
		let optionButton = null;

		if( (pointerAttention || pointerProxyAttention) && !mediaItemEditorInfo?.disableButton && !UIWindow && !isResizing){

			if( showMultipleSelectionButton && partOfMultipleSelection){

				optionButton = <button
					data-editor-overlay="true"				
					class="media-options-button multiple" style={{
						transform: `translate(${Math.floor(contextPos.x*2)/2}px, ${Math.floor(contextPos.y*2)/2}px)`,	
					}}
					onMouseDown={this.onOptionsButtonClick}
					onContextMenu={this.onOptionsButtonContextMenu}
					onMouseUp={this.onOptionsButtonContextMenu}
				>Edit Multiple</button>

			} else if ( !showMultipleSelectionButton && !partOfMultipleSelection){

				optionButton = <button
					data-editor-overlay="true"				
					class={`media-options-button`} style={{
						transform: `translate(${Math.floor(contextPos.x*2)/2}px, ${Math.floor(contextPos.y*2)/2}px)`,	
					}}
					onMouseDown={this.onOptionsButtonClick}
					onContextMenu={this.onOptionsButtonContextMenu}

				>
					<svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle className="inner-circle" cx="8.5" cy="8.65747" r="7.5" />
						<circle className="outer-circle" cx="8.5" cy="8.65747" r="8" />
						<circle className="dot" cx="8.49977" cy="8.65747" r="1" />
						<circle className="dot" cx="5.07" cy="8.65747" r="1" />
						<circle className="dot" cx="11.9295" cy="8.65747" r="1" />
					</svg>
				</button> 
			}

		}

		let resizeButton = null;
		if( !isResizing && pointerAttention && !mediaItemEditorInfo?.disableResize  && !partOfMultipleSelection ){

			resizeButton = <button
				class={`resize-handle ${cursorOrientation}`}
				style={{
					transform: `translate(${Math.floor(handlePos.x*2)/2}px, ${Math.floor(handlePos.y*2)/2}px)`,	
				}}
				onMouseDown={this.onResizePointerDown}
				>
					<svg xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;">
						<polygon class="outline" points="24,18 28,15 30,14 30,30 14,30 15,28 18,24 8,14 4,17 2,18 2,2 18,2 17,4 14,8 "/>
						<polygon class="inner" points="8,11 4,14 4,4 14,4 11,8 24,21 28,18 28,28 18,28 21,24 "/>
					</svg>
				</button>
						
		}

		const additionalUI = mediaItemEditorInfo?.additionalUI?.(this, baseNode) || null;

		// this first div is rotated to match the size and rotation of the actual element
		return (<Fragment key="media-item-editor">
			<style>{`:host {
				${isResizing ? 'visibility: hidden!important;' : ''}
			}
			`}</style>

			<SelectionStatus baseNode={baseNode} onSelectionChange={this.onSelectionChange} />
			{createPortal(<div class="editor-overlay" style={{
				height: size.mediaItemSize.height+'px',
				width: size.mediaItemSize.width+'px',
				display: (pointerAttention || isResizing || pointerProxyAttention ) ? 'block' : 'none',		
				transform: `translate3d(${overlayPosition.x}px, ${overlayPosition.y}px, 1px) rotate(${props.rotation}deg)`
			}}>
				{/** this element is counter-rotated inside it to fit the actual flow-layout**/}
				<div
				class={`figure-outline${this.state.usingLocalScale ? ' using-local-scale' : ''}`}
				style={{
					width: size.mediaItemSize.width+'px',
					height: size.mediaItemSize.height+'px',
					top: editorFramePosition.y+'px',
					left: editorFramePosition.x+'px',
					transform: `rotate(${-props.rotation}deg)`,
				}}>

					{isResizing && pointerAttention &&
						<div 
							className={`tool-tip figure-sizing visible`}
							style={{
								transform: `translate(${Math.floor(contextPos.x*2)/2}px, ${Math.floor(contextPos.y*2)/2}px)`,						
							}}
						>	
							<div className="tooltip-content">
								<span>{`${this.props.limitBy}: ${displayScale}`}</span>
							</div>
						</div>
					}

					{optionButton}
					{resizeButton}
					{additionalUI}


				</div>

				<div
					class="media-ghost-container"
					style={{
						display: isResizing ? 'block' : 'none',
						width: resizeDimensions.width + size.mediaSize.padSize+'px',
						height: resizeDimensions.height+ size.mediaSize.padSize+'px',
		
						top: resizeCorner === 2 || resizeCorner === 3 ? '0' : 'auto',
						right: resizeCorner === 1 || resizeCorner === 2 ? 'auto' : '0',			
						left: resizeCorner === 1 || resizeCorner === 2 ? '0' : 'auto',
						bottom: resizeCorner === 2 || resizeCorner === 3 ? 'auto' : '0'
					
					}}					
				>
					<div
						class="media-ghost"
						ref={this.mediaGhostRef}
					>
					</div>
				</div>
			</div>, this.renderElement)
		}</Fragment>)

	}

	onOptionsButtonContextMenu=(e)=>{
		if( e.metaKey){
			return;
		}
		e.preventDefault();
	}


	onOptionsButtonClick=(e)=>{

		if( this.state.mediaItemEditorInfo?.disableAll){
			return;
		}

		if (
			e.metaKey ||
			e.target.closest('figcaption')
		){
			return;
		}

		e.preventDefault();


		let selectedMediaItems =[];
		mediaItemEditors.forEach(component=>{
			if( component.state.selected ){
				selectedMediaItems.push(component.props.baseNode)
			}
		})		

		// check selected items - if the one being clicked is part of a selection, get all of the items selected
		// otherwise, only pass through the active instance
		if( selectedMediaItems.indexOf(this.props.baseNode) === -1 ){
			// don't ACTUALLY select them (for now)
			// if ( CargoEditor && Object.keys(CargoEditor).length > 0){
			// 	let newRange = CargoEditor.rangy.createRange();
			// 	newRange.selectNode(this.props.baseNode);
			// 	CargoEditor.helpers.setActiveRange(newRange);
			// }
			selectedMediaItems = [this.props.baseNode]

		}

		const mediaItemRect = this.props.baseNode.getBoundingClientRect();
		const svgPosition = {
			x: mediaItemRect.x + 10,
			y: mediaItemRect.y + 10,
			left: mediaItemRect.left + 10,
			right: mediaItemRect.right + 10,
			top: mediaItemRect.top + 10,
			bottom: mediaItemRect.top + 10,
			width: mediaItemRect.width,
			height: 0
		}

		ADMIN_FRAME.adminWindow.UIWindowOpener.openUIWindow({
			windowName: 'imageoptions',
			positionRect: svgPosition,
			closeOnAllClickout: false,
			ignoreEditorWindowClickout: false,
			props: {
				selectedMediaItems,
				clickedMediaItem: this.props.baseNode 
			}
		});	
	}


	setUIWindow = (UIWindow)=>{
		this.setState({
			UIWindow
		})
	}


	onSelectionChange = (selected)=>{

		if( !selected && this.state.showMultipleSelectionButton ){
			this.setState({
				pointerProxyAttention: false,
				showMultipleSelectionButton: false,
				pointerAttention: false,
			})
		}

		this.setState({
			selected,
		})
	}

	checkForEmptyCaption = ()=>{
		const captionEl = this.props.figCaptionElement;
		// check if caption is empty
		if( captionEl){

			// also check for 'caption' class
			if( !captionEl.classList.contains('caption') ){
				captionEl.classList.add('caption')
			}

			const isEmpty = Array.from(captionEl.childNodes).every(node=>node.nodeType == Node.TEXT_NODE && node.nodeValue.trim() === '');

			if( isEmpty && !captionEl.classList.contains('empty') ) {
				captionEl.classList.add('empty')
			} else if ( !isEmpty && captionEl.classList.contains('empty') ){
				captionEl.classList.remove('empty')
			}
		}
	}

	componentDidMount(){

		mediaItemEditors.add(this);
		this.props.baseNode._editorInterface = this;

		this.setState({
			usingLocalScale: this.props.baseNode?.hasAttribute('scale') && this.props.baseNode?.hasAttribute('limit-by')
		})

	
		// right click
		this.props.baseNode.addEventListener('contextmenu', this.onOptionsButtonClick)

		this.checkForEmptyCaption();

		if( this.props.figCaptionElement){
			this.observer.observe(this.props.figCaptionElement, {characterData: true, subtree: true, childList: true});			
		}

	}

	componentWillUnmount(){

		mediaItemEditors.delete(this);
		this.observer.disconnect();
		
		if (this.state.UIWindow){

			if( this.props.baseNode.__willBeReplacedWith){
				const selectedMediaItems = [...this.state.UIWindow.state.selectedMediaItems];
				const indexOf = selectedMediaItems.indexOf(this.props.baseNode);
				if( indexOf > -1){
					selectedMediaItems[indexOf] = this.props.baseNode.__willBeReplacedWith;
				}

				let clickedMediaItem = this.state.UIWindow.state.clickedMediaItem;

				if( this.props.baseNode == clickedMediaItem){
					clickedMediaItem = this.props.baseNode.__willBeReplacedWith;
				}

				this.state.UIWindow.setState({
					selectedMediaItems,
					clickedMediaItem
				}, ()=>{
					this.state.UIWindow?.getSettingsFromNodes();
				})
			} else {
				this.state.UIWindow?.onMutate()

				// this.state.UIWindow.props.removeUIWindow(uiWindow => {
		   		// 	return uiWindow.id === this.state.UIWindow.props.uiWindowProps.id;
		   		// });					
			}
		
		}

		delete this.props.baseNode._editorInterface

		editorOverlayAPI.removePortal(this.props.baseNode, this.renderElement);

		// remove right click
		this.props.baseNode.removeEventListener('contextmenu', this.onOptionsButtonClick)

	}

	updatePosition = (rect)=>{

		if( this.state.pointerAttention || this.state.pointerProxyAttention){

			if( rect.width == 0 || rect.height == 0){
				// this.pointerOut();
			} else {
				this.setEditorFrame();				
			}

		}
	}

	onScroll = (e)=>{

		if(this.ticking){
			return;
		}

		this.ticking = true;
		requestAnimationFrame(()=>{

			const y =  this.props.scrollContext.getScrollPosition().y
			const delta = y - this.scrollPos;

			this.scrollPos = y;

			if(this.state.UIWindow){
				this.state.UIWindow.props.superBadScrollHack.scroll(delta)				
			}

			editorOverlayAPI.testHover();
			this.setEditorFrame();
			this.ticking = false;
		})
	}

	componentDidUpdate(prevProps, prevState){

		// if the figcaption element changed, swap out observers for the new ones and check for empties
		if( this.props.figCaptionElement !== prevProps.figCaptionElement){
			this.observer.disconnect();
			if( this.props.figCaptionElement){
				this.checkForEmptyCaption();
				this.observer.observe(this.props.figCaptionElement, {characterData: true, subtree: true, childList: true})				
			}
		}


		// if there's no height or width set
		// and there's no model height or width
		// and we have an available set of native dimensions
		// set them on the media-item

		if(
			(
				!this.props.height || !this.props.width 
			) && (
				!this.props.model?.width || !this.props.model?.height
			) && (
				this.props.nativeMediaDimensions.width && this.props.nativeMediaDimensions.height
			) && (
				this.props.nativeMediaDimensions.width !== prevProps.nativeMediaDimensions.width ||
				this.props.nativeMediaDimensions.height !== prevProps.nativeMediaDimensions.height ||
				this.props.height !== prevProps.height ||
				this.props.width !== prevProps.width
			)
		){

			CargoEditor?.mutationManager?.execute?.(()=>{
				this.props.baseNode.setAttribute("width", this.props.nativeMediaDimensions.width);
				this.props.baseNode.setAttribute("height", this.props.nativeMediaDimensions.height);
			}, {
				// this change should not be able to force a page into draft mode
				preventDraft: true
			});
		}

		// new src or model incoming... clear custom player + src-derived metadata
		if(
			this.props.src !== prevProps.src ||
			this.props.hash !== prevProps.hash
		){
			CargoEditor?.mutationManager?.execute?.(()=>{
				this.props.baseNode.removeAttribute("width");
				this.props.baseNode.removeAttribute("height");
				this.props.baseNode.removeAttribute('poster');
			}, {
				// this change should not be able to force a page into draft mode
				preventDraft: true
			});
		}


		if(
			this.state.UIWindow !== prevState.UIWindow ||
			this.state.pointerAttention !== prevState.pointerAttention ||
			this.state.pointerProxyAttention !== prevState.pointerProxyAttention ||
			this.props.scrollContext.scrollingElement !== prevProps.scrollContext.scrollingElement
		) {
			if( this.props.scrollContext.scrollingElement &&
				(this.state.UIWindow || this.state.pointerProxyAttention || this.state.pointerAttention)
			){
				this.scrollPos = this.props.scrollContext.getScrollPosition().y
				this.props.scrollContext.scrollingElement.addEventListener('scroll', this.onScroll)

			} else if(prevProps.scrollContext.scrollingElement){
				prevProps.scrollContext.scrollingElement.removeEventListener('scroll', this.onScroll);	
			}
		}



		if(
			(this.props.size.mediaSize.width !== prevProps.size.mediaSize.width ||
			this.props.size.mediaSize.height !== prevProps.size.mediaSize.height ||
			this.props.size.mediaItemSize.width !== prevProps.size.mediaItemSize.width ||
			this.props.size.mediaItemSize.height !== prevProps.size.mediaItemSize.height ||
			this.props.size.mediaSize.padSize !== prevProps.size.mediaSize.padSize ||
			this.props.size.mediaItemSize.padSize !== prevProps.size.mediaItemSize.padSize ||			
			this.props.rotation !== prevProps.rotation ) &&

			(this.state.pointerAttention || this.state.pointerProxyAttention)
		) {
			this.setState({
				usingLocalScale: this.props.baseNode?.hasAttribute('scale') && this.props.baseNode?.hasAttribute('limit-by')
			})			
		
			editorOverlayAPI.testHover();
			this.setEditorFrame();
		}

	}


	pointerIn = ()=>{
		const mediaItemEditorInfo = this.props.baseNode?.parentNode?._mediaItemEditorInfo || {}

		const gallerySlideshowParent = this.props.baseNode.parentElement?.nodeName == 'GALLERY-SLIDESHOW' ? this.props.baseNode.parentElement : null
		if(
			gallerySlideshowParent &&
			!gallerySlideshowParent.thumbnailsVisible &&
			!this.props.baseNode.assignedSlot?.parentElement?.hasAttribute?.('data-active-slide')
		) {
			this.pointerOut();
			return
		}

		this.setState({
			mediaItemEditorInfo
		})

		if( this.state.selected){
			mediaItemEditors.forEach(component=>{

				if( component.state.showMultipleSelectionButton ){
					component.setState({
						pointerProxyAttention: true,
					})
				}
			});			
		}

		this.setState({
			pointerAttention: true
		}, ()=>{
			this.setEditorFrame();	
		})

	}	

	pointerOut = ()=>{

		if(this.state.selected ){
			mediaItemEditors.forEach(component=>{
				if( component.state.pointerProxyAttention ){
					component.setState({
						pointerProxyAttention: false,
					})
				}
			});	
		}

		this.setState({
			pointerAttention: false
		})		
	}

	onResizePointerDown = (e)=>{

		if(e.button ==2 ){
			return;
		}


		const mediaElement = this.props.mediaElement;
		const mediaGhostRef = this.mediaGhostRef.current;

		if( !mediaElement || !mediaGhostRef){
			return;
		}

		if(this.props.size.width?.indexOf?.('%') > -1){
			return;
		}		


		while (mediaGhostRef.lastChild){
			mediaGhostRef.lastChild.remove();
		}

		if( mediaElement.tagName == 'IMG' ){
			const clone = mediaElement.cloneNode(true);
			clone.setAttribute('decoding', 'sync')
			mediaGhostRef.appendChild(clone);
		} else if (mediaElement.tagName === 'VIDEO' && mediaElement.hasAttribute('poster') ) {
			const posterImg = document.createElement('img');
			posterImg.setAttribute('decoding', 'sync')
			posterImg.setAttribute('src', mediaElement.getAttribute('poster'))
			mediaGhostRef.appendChild(posterImg);
		} else if (mediaElement.classList.contains('placeholder') ){
			mediaGhostRef.appendChild(mediaElement.cloneNode(true));
		} else {
			mediaGhostRef.appendChild(document.createElement('div'));
		}

		const style = window.getComputedStyle(mediaElement);		
		mediaGhostRef.firstChild.style.border = style.getPropertyValue('border');
		mediaGhostRef.firstChild.style.borderRadius = style.getPropertyValue('border-radius');
		mediaGhostRef.firstChild.style.padding = style.getPropertyValue('padding');
		mediaGhostRef.firstChild.style.outline = style.getPropertyValue('outline');

		this.startDrag(e)
	}

	startDrag = (e)=>{
		const {
			handlePos,
			mediaCornerPositions,
			cursorOrientation,
		} = this.state;

		const {

			baseNode,
			rotation,
			limitBy,
			size
		} = this.props;


		let limitingElement = this.props.baseNode.closest('bodycopy, column-unit, gallery-freeform');
		let scalingLimit = 0;

		if(limitingElement){
			let limitStyle = window.getComputedStyle(limitingElement);
			scalingLimit = limitingElement.offsetWidth + -parseFloat(limitStyle.getPropertyValue('padding-left')) + -parseFloat(limitStyle.getPropertyValue('padding-right'));
		} else {
			scalingLimit = window.innerWidth;
		}

		scalingLimit = scalingLimit + -size.mediaSize.padSize + -size.mediaItemSize.padSize;

		const dragStartPosition ={
			x: e.clientX,
			y: e.clientY,
		}

		e.preventDefault();




		let initialScale = this.props.scale ?? 100;
		let scaleArray = helpers.getCSSValueAndUnit(initialScale, (limitBy ==='width' ? '%' : 'rem'));
		if( limitBy !=='width' && limitBy !== 'height'){
			scaleArray[1] = ''
		}
		scaleArray[0] = parseFloat(scaleArray[0])



		// if we have a scale that's invalid for the purposes of resizing ( calc() / var() / junk value)
		// we reset it to a measured width:
		if(
			scaleArray[0] === '' ||
			scaleArray[1].indexOf('calc(') > -1 ||
			scaleArray[1].indexOf('var(') > -1
		) {
			if( limitBy ==='width'){
				scaleArray[0] = (size.mediaSize.width/scalingLimit)*100;
				scaleArray[1] = '%'
			} else {
				scaleArray[0] = (size.mediaSize.height/window.innerHeight)*100;
				scaleArray[1] = 'vh'
			}
		}

		const decimalPlaces = Math.min( scaleArray[0].toString().indexOf('.') > -1 ? scaleArray[0].toString().split('.').pop().length : 0, 3) 
		scaleArray[0] = parseFloat(parseFloat(scaleArray[0]).toFixed(decimalPlaces));


		let resizeDimensions = {
			width: size.mediaSize.width,
			height: size.mediaSize.height,
			atLimit: Math.abs(size.mediaSize.width - scalingLimit) < 2,
			scaleArray: scaleArray,
			scale: initialScale,
			overSize: 1,
			displayScale: null
		}

		if( this.state.mediaItemEditorInfo.beforeScaleChange){
			resizeDimensions = this.state.mediaItemEditorInfo.beforeScaleChange(this.props.baseNode, resizeDimensions);
		}

		this.setState({
			dragStartPosition,
			scalingLimit: scalingLimit,

			dragDelta: {
				x: 0,
				y: 0,
			},
			resizeDimensions,
			initialDimensions: {
				...resizeDimensions,
				scaleArray: [...resizeDimensions.scaleArray]
			},
			isResizing: true,
		}, ()=>{

			editorOverlayAPI.lock();
			document.body.style.setProperty('cursor', cursorOrientation, 'important')
			document.body.classList.add('dragging-figure-in-editor');	

			ADMIN_FRAME.adminWindow.addEventListener('mouseup', this.onPointerUp);
			ADMIN_FRAME.adminWindow.addEventListener('pointermove', this.onDrag)

			window.addEventListener('pointermove', this.onDrag)
			window.addEventListener('mouseup', this.onPointerUp)
		});		
	}


	onDrag =(e)=>{

		// here there be dragons

		e.preventDefault();


		const {
			dragStartPosition,
			mediaCornerPositions,
			scalingLimit,
			resizeCorner,
			handlePos,
			handleTrack,
			initialDimensions,
		} = this.state;

		const {
			rotation,
			limitBy,
			baseNode,
			size,
		} = this.props;

		let {
			resizeDimensions
		} = this.state

		let {
			scale,
			scaleArray
		} = initialDimensions;


		const dragDelta = {
			x: e.clientX - dragStartPosition.x,
			y: e.clientY +- dragStartPosition.y, 
		}

		const radians = rotation* (Math.PI/180) || 0;

		let b1 = mediaCornerPositions[resizeCorner];
		let c1 = mediaCornerPositions[(resizeCorner+1)%4];
		let d1 = mediaCornerPositions[(resizeCorner+2)%4];		
		let a1 = mediaCornerPositions[(resizeCorner+3)%4];


		let pointerToA = this.getDistance(a1, handlePos)
		let pointerToD = this.getDistance(d1, handlePos)


		let baseWidth = parseInt(size.width);
		let baseHeight = parseInt(size.height);

		let buttonHeight =45;
/**


		d1         
        •--------------• a1
        |          ;   |
        |          ;   |
        |          ;   | 
	    |          ;   | 
        |~ ~ ~ ~ ~ ◉   |
        |              |
        |             h| 
        •--------------• b1
        c1         	                    
              

**/

		let angleBA = this.getAngle(a1, b1);
		let angleCB = this.getAngle(b1, c1);

		let pointerPos = {
			x: handlePos.x + dragDelta.x,
			y: handlePos.y + dragDelta.y,
		}

		let handleBottomCastCB =[{
			x: handlePos.x + Math.cos(angleCB)*10,
			y: handleTrack.y+handleTrack.h+-buttonHeight + Math.sin(angleCB+Math.PI)*10,
		}, {
			x: handlePos.x,
			y: handleTrack.y+handleTrack.h+-buttonHeight,
		}]

		let handleBottomCastAB =[{
			x: handlePos.x + Math.cos(angleBA)*10,
			y: handleTrack.y+handleTrack.h+-buttonHeight + Math.sin(angleBA+Math.PI)*10,
		}, {
			x: handlePos.x,
			y: handleTrack.y+handleTrack.h+-buttonHeight,
		}];

		let widthResize = 0;
		let heightResize = 0;
		let diagonalResize = 0;

		let width, height;

		if( resizeCorner%2 == 0 ){
			let handleCD = this.getIntersection(handleBottomCastCB, [c1, d1]);
			let handleDA = this.getIntersection(handleBottomCastAB, [d1, a1]);

			let handleToCorner = this.getDistance(d1, handlePos);
			let pointerToCorner = this.getDistance(d1, pointerPos);
			let angleToPointer = this.getAngle(d1, pointerPos)

			let referenceDiagonal = this.getDistance(d1, handlePos)
			diagonalResize = pointerToCorner/referenceDiagonal;

			let referenceHeight = this.getDistance(d1, handleCD);
			heightResize = (pointerToCorner/referenceHeight)
			let heightInfluence = Math.abs(Math.sin(angleToPointer+radians));
			heightResize*=heightInfluence
			

			let referenceWidth = this.getDistance(handleDA, d1);
			widthResize = (pointerToCorner/referenceWidth)
			let widthInfluence = Math.cos(angleToPointer+radians)
			widthResize*=widthInfluence


		} else {

			let handleAB = this.getIntersection(handleBottomCastCB, [a1, b1]);
			let handleBC = this.getIntersection(handleBottomCastAB, [b1, c1]);

			let handleToCorner = this.getDistance(d1, handlePos);
			let pointerToCorner = this.getDistance(d1, pointerPos);
			let angleToPointer = this.getAngle(d1, pointerPos)

			let referenceDiagonal = this.getDistance(d1, handlePos)
			diagonalResize = pointerToCorner/referenceDiagonal;

			let referenceHeight = this.getDistance(d1, handleAB);
			heightResize = (pointerToCorner/referenceHeight)
			let heightInfluence = Math.abs(Math.sin(angleToPointer+radians));
			heightResize*=heightInfluence
			

			let referenceWidth = this.getDistance(handleBC, d1);
			widthResize = (pointerToCorner/referenceWidth)
			let widthInfluence = Math.cos(angleToPointer+radians)
			widthResize*=widthInfluence

		}


		let maxResize = Math.max(widthResize, diagonalResize, heightResize);
		let scaleDown = Math.min(size.mediaSize.width/(baseWidth), size.mediaSize.height/baseHeight);
		let imageRatio = baseWidth/baseHeight;

		width = (baseWidth*scaleDown)*maxResize;
		height = (baseHeight*scaleDown)*maxResize;


		let atLimit = false;
		let overSize = 1;


		if(width > scalingLimit) {
			let scaleDown = scalingLimit/width;
			atLimit = true;
			width = scalingLimit;
			height = height*scaleDown
		}

		let snapValue = e.altKey || e.metaKey;

		// one dimension is auto-scaled and one is not, so we scale up the opposing size accordingly
		if ( limitBy === 'width' ){
			[scaleArray, width] = this.scaleDimension(scaleArray, width+size.mediaSize.padSize+size.mediaItemSize.padSize, size.mediaSize.width+size.mediaSize.padSize+size.mediaItemSize.padSize, snapValue);
			width = width + -size.mediaSize.padSize + -size.mediaItemSize.padSize;
			height = (width)/imageRatio;
		} else {
			[scaleArray, height] = this.scaleDimension(scaleArray, height+size.mediaSize.padSize+size.mediaItemSize.padSize, size.mediaSize.height+size.mediaSize.padSize+size.mediaItemSize.padSize, snapValue);
			width = imageRatio*(height + -size.mediaSize.padSize + -size.mediaItemSize.padSize);
			height = (width)/imageRatio;			
		}
		scaleArray[0] = parseFloat(scaleArray[0])
		resizeDimensions = {
			scale: scaleArray.join(''),
			scaleArray: scaleArray,
			limitBy,
			width,
			height,
			atLimit,
			overSize,
			displayScale: null
		}

		if( scaleArray[1] == '%' && limitBy == 'width' && parseFloat(scaleArray[0]) >=100 ){
			resizeDimensions.scale = '100%';
			resizeDimensions.scaleArray = [100, '%']
		}		

		// allow galleries to intervene in scale change
		if( this.state.mediaItemEditorInfo.whileScaleChange ){
			resizeDimensions = this.state.mediaItemEditorInfo.whileScaleChange(this.props.baseNode, {...resizeDimensions } )
		}

		this.setState({resizeDimensions});

	}

	scaleDimension = (separatedCSSValue, resizePxValue, referencePxValue, snapValue) =>{

		let newVal = separatedCSSValue[0]*(resizePxValue/referencePxValue);
		let unRoundedValue = newVal;

		if( snapValue ){
			if(
				separatedCSSValue[1]=='%' ||
				separatedCSSValue[1]=='vh' ||
				separatedCSSValue[1]=='vw' ||
				separatedCSSValue[1]=='vmax' ||
				separatedCSSValue[1]=='vmin'
			) {
				newVal = Math.round(newVal/.5)*.5;
			} else {
				newVal = Math.round(newVal/10)*10;	
			}

		} else {

			if ( separatedCSSValue[1]=='px') {
				newVal = Math.round(newVal)					
			} else {
				newVal = Math.round(newVal*10)/10;
			}			
		}
		
		newVal = Math.max(1, newVal);

		let diff = newVal/unRoundedValue || 0;

		return [
			[newVal, separatedCSSValue[1]],
			resizePxValue*diff
		]
	}

	onPointerUp = (e)=>{

		const {
			resizeDimensions,
			scalingLimit,
		} = this.state;

		e.preventDefault();

		CargoEditor.mutationManager.execute(()=>{
			if( this.state.mediaItemEditorInfo.afterScaleChange ){
				this.state.mediaItemEditorInfo.afterScaleChange(this.props.baseNode, resizeDimensions);
			} else {
				this.props.baseNode.setAttribute('scale', resizeDimensions.scale);
			}
			this.props.baseNode.flushMutationQueue();
		})

		editorOverlayAPI.unlock();

		this.setState({
			isResizing: false,
		}, ()=>{

			document.body.style.cursor = '';
			ADMIN_FRAME.adminWindow.removeEventListener('mouseup', this.onPointerUp);		
			ADMIN_FRAME.adminWindow.removeEventListener('pointermove', this.onDrag)

			document.body.classList.remove('dragging-figure-in-editor');
			window.removeEventListener('pointermove', this.onDrag)
			window.removeEventListener('mouseup', this.onPointerUp)
		});
		
	}

	// even more dragons
	setEditorFrame = ()=>{

		const {
			figureElement,
			mediaElement,
			sizingFrameElement,
			baseNode,
			rotation,
			size,
		} = this.props;

		if(
			!figureElement ||
			!mediaElement ||
			!mediaElement.parentElement ||
			size.mediaItemSize.width == 0 ||
			size.mediaItemSize.height == 0 ||
			!sizingFrameElement || 
			(!this.state.pointerAttention && !this.state.pointerProxyAttention)
		){
			return;
		}


		let radians = rotation* (Math.PI/180) || 0;

		const mediaItemRect = baseNode.getBoundingClientRect();

		let rotatedDimensions = {
			w: size.mediaItemSize.width *Math.cos(radians) - size.mediaItemSize.height*Math.sin(radians),
			h: size.mediaItemSize.width *Math.sin(radians) + size.mediaItemSize.height*Math.cos(radians)
		}

		let xTransform = 0;
		let yTransform = 0;


		const overlayPosition = {
			x: mediaItemRect.left + (mediaItemRect.width - rotatedDimensions.w)*.5 + -xTransform,
			y: mediaItemRect.top + (mediaItemRect.height - rotatedDimensions.h)*.5 + -yTransform
		};


		const mediaRect = sizingFrameElement.getBoundingClientRect();

		let buttonWidth = 45;
		let buttonHeight = 45;

		let useMultiSelectHeight = this.state.showMultipleSelectionButton && this.state.multiSelectHeight;

		let figureOffset = {
			x: figureElement.offsetLeft,
			y: figureElement.offsetTop,
			w: figureElement.offsetWidth,
			h: figureElement.offsetHeight,
		}

		if( useMultiSelectHeight){
			figureOffset.h = this.state.multiSelectHeight
		}

		let mediaOffsetLeft = mediaElement.offsetLeft;
		let mediaOffsetTop = mediaElement.offsetTop;
		let mediaParent = mediaElement;
		while(
			mediaParent &&
			mediaParent.parentNode &&
			mediaParent.parentNode !== baseNode.shadowRoot &&
			mediaParent.parentNode !== figureElement && 
			mediaParent.parentNode !== baseNode
		){


			mediaOffsetLeft = mediaOffsetLeft+mediaParent.offsetLeft
			mediaOffsetTop = mediaOffsetTop+mediaParent.offsetTop				
			mediaParent = mediaParent.offsetParent			

		}


		let mediaOffset = {
			x: mediaOffsetLeft || 0,
			y: mediaOffsetTop || 0
		}

		let unRotatedMediaPositions = [
			{
				x: mediaOffset.x,
				y: mediaOffset.y,
			},

			// top right			
			{
				x: mediaOffset.x+size.mediaSize.width+size.mediaSize.padSize,
				y: mediaOffset.y,
			},

			// bottom right
			{
				x: mediaOffset.x+size.mediaSize.width+size.mediaSize.padSize,
				y: mediaOffset.y+size.mediaSize.height+size.mediaSize.padSize,
			}, 
			
			// bottom left
			{
				x: mediaOffset.x,
				y: mediaOffset.y+size.mediaSize.height+size.mediaSize.padSize,
			}, 			
		]

		let mediaCornerPositions = [];

		if ( useMultiSelectHeight){
			mediaCornerPositions = unRotatedMediaPositions

			mediaCornerPositions[2].y = mediaOffset.y + this.state.multiSelectHeight;
			mediaCornerPositions[3].y = mediaOffset.y + this.state.multiSelectHeight;

		} else {
			let center = {
				x: figureOffset.w *.5,
				y: figureOffset.h *.5,
			}

			mediaCornerPositions = unRotatedMediaPositions.map(pos=>{

				// translate point to origin
				let temp = {
					x: pos.x - center.x,
					y: pos.y - center.y
				}

				// now apply rotation
				let rotated = {
					x: temp.x*Math.cos(radians) - temp.y*Math.sin(radians),
					y: temp.x*Math.sin(radians) + temp.y*Math.cos(radians)
				}

				// translate back
				return {
					x: rotated.x + center.x,
					y: rotated.y + center.y
				}

			})
		}


		// if we're dealing with something zeroed out... cancel
		if( _.isEqual(mediaCornerPositions) ){
			return
		}


		/*

     0 --x-- +
     |
     y
     |
     +
     
                  a1
                 ╱  ╲
		        ╱    ╲
			   ╱      ╲ 
		      ╱        ╲
			 ╱          ╲
		 d1 ╱            ╲ b1
			╲            ╱
			 ╲          ╱
		      ╲        ╱
			   ╲      ╱
				╲    ╱
                 ╲  ╱
                  c1
		*/

		// set up coordinates regardless of actual rotation
		let a1, b1, c1, d1
		a1 = _.minBy(mediaCornerPositions, pos=>pos.y);
		let startIndex = mediaCornerPositions.indexOf(a1);
		
		b1 = mediaCornerPositions[(startIndex+1)%4]
		c1 = mediaCornerPositions[(startIndex+2)%4]
		d1 = mediaCornerPositions[(startIndex+3)%4]

		// position and 'track' for resize-handle to be on
		let handleTrack = {
			x: 0,
			y: 0,
			w: buttonWidth,
			h: buttonHeight,
		};

		let handlePos = {
			x: 0,
			y: 0,
		}

		let contextPos = {
			x: a1.x,
			y: a1.y,
		}		

		// bounding box is essentially same as mediaRect, but mapped to to the local coordinate space
		let bb = {
			w: b1.x - d1.x,
			h: c1.y - a1.y,
			x: d1.x,
			y: a1.y
		}

		// cast a line from the center down and to the right
		let lineFromCenter = [
			{x: bb.x+bb.w*.5, y: bb.y+bb.h*.5 },
			{x: bb.x+bb.w, y: bb.y+bb.h }
		]

		let intersectionWithBottomRight = this.getIntersection(lineFromCenter,[
			b1,
			c1
		]);
		let intersectionWithBottomLeft = this.getIntersection(lineFromCenter,[
			d1,
			c1
		]);

		//  the button can either cling to the bottom right corner or to the bottom corner
		//  I don't know how to generalize all this math yet, so these are treated as two separate cases

		let resizeButtonAtRightCorner = false;

		// if b1 is closer than c1 to our ray, choose b1
		let closerIntersection = _.minBy([intersectionWithBottomLeft, intersectionWithBottomRight], (pos)=>{

			// remove intersections that happen above halfway point
			if ( pos.y < lineFromCenter[0].y){
				return 9e9;
			}
			return pos.y
		});

		if( closerIntersection == intersectionWithBottomLeft ){

			handleTrack.x = c1.x;
			handleTrack.y = c1.y

		} else {
			let distanceToB1 = this.getDistance(b1, closerIntersection);
			let distanceToC1 = this.getDistance(c1, closerIntersection);
			if ( distanceToC1 < distanceToB1 ){
				handleTrack.x = c1.x;
				handleTrack.y = c1.y
			} else {
				handleTrack.x = b1.x;
				handleTrack.y = b1.y;
				resizeButtonAtRightCorner = true;
			}
		}

		let y2 
		let shiftOver = 0;
		let shiftDown = 0;

		if ( resizeButtonAtRightCorner ){

			

			/**

						|\ aH 
						|  \
						|    \ bR
						|      \
						|        \
		buttonHeight	|          \ 90° , b1
						|          /
		                |        /
		                |      / bL
		                |    /
						|  /
						|/ aG

			**/

			let aH = this.getAngle(b1, a1);
			let aG = Math.PI - (Math.abs(aH) + (Math.PI*.5));

			let bL = buttonHeight * Math.cos(aG);

			let xShift = -Math.cos(aG+Math.PI*.5)*bL;
			let yShift = -Math.sin(aG+Math.PI*.5)*bL;

			// move context to left corner

			contextPos = {
				x: d1.x+-xShift,
				y: d1.y+-yShift+buttonHeight,
			}			

			handleTrack.x = handleTrack.x + xShift +-buttonWidth;

			y2 = handleTrack.y + yShift +buttonHeight

			let lineFromLeftSideOfHandle = [
				{
					x: a1.x-buttonWidth,
					y: y2
				},
				{
					x: a1.x-buttonWidth,
					y: y2 -10
				}
			]

			let lineFromRightSideOfHandle = [
				{
					x: a1.x,
					y: y2
				},
				{
					x: a1.x,
					y: y2 -10
				}
			]

			let testIntersections = [
				this.getIntersection(lineFromLeftSideOfHandle,[
					d1,
					a1
				]),
				this.getIntersection(lineFromLeftSideOfHandle,[
					a1,
					b1
				]),
				this.getIntersection(lineFromRightSideOfHandle,[
					d1,
					a1
				]),
				this.getIntersection(lineFromRightSideOfHandle,[
					a1,
					b1
				])
			]

			let maxPos = _.maxBy(testIntersections, pos => pos.y);

			handleTrack.y = maxPos.y;
			handleTrack.h = y2 - handleTrack.y;

			let overlap = Math.max(0,mediaRect.bottom+(buttonHeight+yShift) +-windowInfo.data.window.h + - ( c1.y - b1.y));

			shiftDown = (handleTrack.h-buttonHeight)+-overlap;

			let interSectionWithRightSide =this.getIntersection([
				{
					x: handleTrack.x,
					y: handleTrack.y+shiftDown
				},
				{
					x: handleTrack.x-10,
					y: handleTrack.y+shiftDown
				}
			],[
				a1,
				b1
			])

			shiftOver =  (interSectionWithRightSide.x-handleTrack.x)+-buttonWidth;
			
		} else {

		/**
					 
			angle, position

							buttonWidth
				    aG  ___________________  aH 
						\                 /
				          \              /
				            \           /
				        bL    \        /  bR
				                \     /
				                  \  /
				                   90°, c1


			for contextMenu button

			           a1
			 a1cM1    /	  \a1cM2
			         /      \
			    cM1 /__________\cM2 
			           \
			       buttonWidth
		**/			


			let aH = this.getAngle(c1, b1);
			let aG = Math.PI - (Math.abs(aH) + (Math.PI*.5));
			let bL = buttonWidth * Math.cos(aG);
			
			// find bottom position
			let xShift = -Math.cos(aG)*bL;
			let yShift = - Math.sin(aG)*bL;

			y2 = handleTrack.y + yShift;

			handleTrack.x = handleTrack.x + xShift
			handleTrack.y = b1.y;
			handleTrack.h = y2 - b1.y;

			contextPos = {
				x: a1.x+-xShift+-buttonWidth,
				y: a1.y+-yShift,
			}			

			// if the ascending side is steeper than 45°, we cling to the side
			let rightSideSlope = Math.abs(this.getSlope(b1,c1));
			
			if (rightSideSlope > 1 && mediaRect.bottom+yShift >= windowInfo.data.window.h ){

				// top of the track needs some extra room
				// handleTrack.y = handleTrack.y -yShift;
				// handleTrack.h = y2 - handleTrack.y;

				/**

										handleTrack.w	
										↓
									  |-----——|       /
						   \          |     | |  shiftOver
							 \		  |   c2| |← ┴ →/
					           ____________________  posJ    __ window border
								\     |     | |   /
						          \   |     | |  / 
						            \ |     | | /
						              \_____|_|/  
						                \   | / ↖︎ [handleTrack.x+handleTrack.w, y2]
						                  \ |/
						                   90°, c1

				**/


				let overlap = mediaRect.bottom  + -windowInfo.data.window.h ;
				let c2 = {
					x: c1.x,
					y: c1.y - overlap
				}

				let posJ = this.getIntersection([
					c2,
					{
						x: c2.x + 10,
						y: c2.y
					}
				],[
					c1,
					b1
				]);

				shiftOver = posJ.x - (handleTrack.x+handleTrack.w)
				shiftDown = posJ.y - handleTrack.y + -buttonHeight

			} else {


				let lineFromLeftSideOfHandle = [
					{
						x: handleTrack.x,
						y: y2
					},
					{
						x: handleTrack.x,
						y: y2 -10
					}
				]

				let lineFromRightSideOfHandle = [
					{
						x: handleTrack.x+handleTrack.w,
						y: y2
					},
					{
						x: handleTrack.x+handleTrack.w,
						y: y2 -10
					}
				]

				let testIntersections = [
					this.getIntersection(lineFromLeftSideOfHandle,[
						d1,
						a1
					]),
					this.getIntersection(lineFromLeftSideOfHandle,[
						a1,
						b1
					]),
					this.getIntersection(lineFromRightSideOfHandle,[
						d1,
						a1
					]),
					this.getIntersection(lineFromRightSideOfHandle,[
						a1,
						b1
					])
				]

				let maxPos = _.maxBy(testIntersections, pos => pos.y);
				
				handleTrack.y = maxPos.y;
				handleTrack.h = y2 - handleTrack.y;

				let overlap = Math.max(0,mediaRect.bottom+-windowInfo.data.window.h+yShift);

				shiftDown = (handleTrack.h-buttonHeight)+-overlap
				shiftOver = 0;
			}

		}

		handlePos.x = handleTrack.x+shiftOver;
		handlePos.y = Math.max(handleTrack.y , handleTrack.y+shiftDown);

		let xOverlap = Math.max(0,(mediaRect.left + mediaRect.width) +- windowInfo.data.window.w);
		handlePos.x = Math.min(handlePos.x, b1.x - (xOverlap+buttonWidth))


		/**
		 *  now that handle position is set up, position the context menu button -
		 *  just make sure it stays inside the window - nothing fancy otherwise
		 **/

		let xOverlapLeft = Math.min(0,mediaRect.left);
		let yOverlapTop = Math.min(0,mediaRect.top);

		contextPos.x = Math.max(contextPos.x, -xOverlapLeft+d1.x)
		contextPos.y = Math.max(contextPos.y, -yOverlapTop+a1.y)

		let contextIntersectionWithD1C1 = this.getIntersection(
		[
			contextPos,
			{
				x: contextPos.x,
				y: contextPos.y+10
			}
		],[
			d1,
			c1
		])

		contextPos.y = Math.min(contextPos.y, contextIntersectionWithD1C1.y +- buttonHeight);



		let resizeCorner = (startIndex+2)%4
		if( resizeButtonAtRightCorner ){
			resizeCorner = (resizeCorner+3)%4;
		}

		let cursorOrientation = 'nwse-resize';
		

		let opposingAngle = this.getAngle(handlePos, mediaCornerPositions[(resizeCorner+2)%4]);
		if ( Math.abs(Math.PI*.5 - opposingAngle )< .4){
			cursorOrientation = 'ns-resize';
		} else if ( Math.abs(Math.PI - Math.abs(opposingAngle)) < .4){
			cursorOrientation = 'ew-resize';
		}

		this.setState({
			mediaCornerPositions,
			resizeCorner,
			contextPos,
			handlePos,
			cursorOrientation,
			handleTrack,
			overlayPosition,

			editorMediaFramePosition: {
				x: mediaOffset.x,
				y: mediaOffset.y
			},
			editorFramePosition: {
				x: 0,
				y: 0
			},
		})
	}

	getAngle = (pointA, pointB) => {
		return Math.atan2(-(pointB.y - pointA.y), pointB.x - pointA.x)
	}

	getDistance = (pointA, pointB)=>{
		return Math.sqrt( Math.pow( (pointB.y - pointA.y ), 2) + Math.pow( (pointB.x - pointA.x ), 2));
	}

	getSlope = (pointA, pointB)=>{
		return (pointB.y - pointA.y )/(pointB.x - pointA.x )
	}

	getIntersection = (lineA, lineB)=>{

		let slopeA = this.getSlope(...lineA);
		let slopeB = this.getSlope(...lineB);

		let h, k;

		if( slopeA == 0){

			if( !isFinite(slopeB) ){
				h = lineB[0].x;
			} else {
				h = ((slopeB * lineB[0].x ) - (lineB[0].y - lineA[0].y) ) / slopeB;	
			}
			k = lineA[0].y;
		} else {

			if (!isFinite(slopeA)) {
				h = lineA[0].x;
				k = (slopeB*(h - lineB[0].x))+ lineB[0].y
			} else {
				h = (((slopeB * lineB[0].x) - (slopeA * lineA[0].x)) - (lineB[0].y - lineA[0].y) ) / ( slopeB - slopeA );
				k = slopeA * (h - lineA[0].x) +lineA[0].y;	
			}
			
		}

		if ( !isFinite(h) ){
			h = null;
		}

		if ( !isFinite(k)){
			k = null;
		}

		return {x: h, y: k}	
	}

};

export default MediaItemEditor
