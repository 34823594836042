import React, { useEffect, forwardRef, useRef, useState } from 'react';
import _ from 'lodash';

const MemoizedInputChild = forwardRef((props, ref) => {
    const { field, form, onEnter, onBlur, onFocus, onKeyDown, setHint, setSuggestion, maxLength, focusOnInit, formatFields, setIsFocused, placeholder, deriveSuggestion, suggestion } = props;

    const inputRef = useRef();

    // Manage event binding
    useEffect(() => {
        // Sets autofill input from outside. 
        function setAutofillValue(e){
            
            if( e.detail.name !== field.name ){ return }

            form.setFieldValue(
                field.name, 
                e.detail.message, 
                false
            )

            focusInputEnd()
            setSuggestion('')
            setHint('')
        }

        document.addEventListener('set-autofill-from-parent', setAutofillValue)
        return () => {
        // cleanup
        document.removeEventListener('set-autofill-from-parent', setAutofillValue);
        };
    }, []);

    useEffect(() => {
        inputRef.current.textContent = field.value;

        if( focusOnInit ){
            focusInputEnd();
        }

    }, [])

    useEffect(() => {

        if (inputRef.current.textContent !== field.value) {
            if( field.value === "" || !field.value ){
                inputRef.current.innerHTML = '';
            } else {
                inputRef.current.textContent = field.value;
            }
        }

    }, [field.value])

    useEffect(() => {
        const targetElement = inputRef.current;
        // Create an observer instance to monitor for changes in the input that aren't text.
        // Safari, for example, loves inserting <br> tags when you clear the editable field.
        const observer = new MutationObserver(function (mutationsList) {
          for (var mutation of mutationsList) {
            if ( mutation.type === 'childList' && mutation.addedNodes.length > 0 ) {
              mutation.addedNodes.forEach(function (addedNode) {
                // Check for any added elements and remove them. This won't effect text.
                if (addedNode.nodeType !== Node.TEXT_NODE) {
                   addedNode.remove();
                }
              });
            }
          }
        });
        // Configuration of the observer...
        const observerConfig = { childList: true, subtree: true };
        // Start observing the target element for changes in the DOM
        observer.observe(targetElement, observerConfig);
        // Clean up the observer when the component unmounts
        return () => {
          observer.disconnect();
        };
      }, []);

    const handleInput = (e) => {

        if( e && e.which !== 13 && ( keyIsValid(e.which) || e.which === 91 ) ) {

            const newValue = e.target.textContent;
            newValue.replace(/\s+/g, '');

            if ( form && newValue !== field.value ) {
                form.setFieldValue(
                    field.name, 
                    newValue, 
                    false
                )
            }

            if( 
                typeof value !== "undefined" 
                && value !== null 
                && newValue.trim() === "" 
                && newValue.length === 0 
            ) {
                inputRef.current.innerHTML = '';
            }

        }

        if( e 
            && ( e.which == 13 || e.key === 'Enter' ) 
            && suggestion 
            && suggestion.length > 0 
        ){
            e.preventDefault();

            form.setFieldValue(
                field.name, 
                suggestion, 
                false
            )

            deriveSuggestion(null);
            focusInputEnd();
        }

        if(  e.type === 'keyup' 
        && ( e.which == 13 || e.key === 'Enter' ) 
        && ( !suggestion || suggestion?.length === 0 )
        ){
            e.preventDefault();
            if( onEnter ){ onEnter(e); }
        }
    

    };

    const keyIsValid = (keycode) => {
        const valid =  
            (keycode > 7 && keycode < 14)    || // backspace tab enter
            (keycode > 45 && keycode < 58)   || // number keys
            keycode == 32                    || // spacebar
            (keycode > 64 && keycode < 91)   || // letter keys
            (keycode > 95 && keycode < 112)  || // numpad keys
            (keycode > 185 && keycode < 193) || // ;=,-./` (in order)
            (keycode > 218 && keycode < 223);   // [\]' (in order)

        return valid;
    }

    const focusInputEnd = () => { 
        window.requestAnimationFrame(()=>{
            let inputLength = field?.value.length || 0;

            if( inputRef?.current && inputLength !== undefined ){
                inputRef?.current.focus();
                // inputRef?.current.setSelectionRange(inputLength, inputLength+1)
                document.execCommand('selectAll', false, null);
                // collapse selection to the end
                document.getSelection().collapseToEnd();
                inputRef.current.parentElement.scrollLeft = inputRef.current.parentElement.scrollWidth;
            }
        });
    }

    const preventContentEditableKeys = (e) => {

        formatFields(); // Measure for "..."
        
        let currentLength  = inputRef?.current?.textContent.length || 0;

        if( onKeyDown ){
            onKeyDown(e)
        }

        // enforce max length.
        if (maxLength && (currentLength + 1) >= maxLength) {
            // allow backspace
            if( e.which == 8 ){
                return
            }
            e.preventDefault();
            e.nativeEvent.stopImmediatePropagation();
            return;
        }
   
        if( e 
            && ( e.which == 9 || e.key === 'Tab' )  
            && suggestion 
            && suggestion.length > 0 
        ){
            e.preventDefault();

            form.setFieldValue(
                field.name, 
                suggestion, 
                false
            )

            deriveSuggestion(null);
            focusInputEnd();
        }
        
        if ( e.which == 13 ) {
            e.preventDefault();
        }

        return e.which != 13;
    }

    const handlePaste = (e) => {

        let pastedText = e.nativeEvent.clipboardData.getData('text/plain');

        // prevent normal behavior
        e.stopPropagation();
        e.preventDefault();

        pastedText = pastedText.replace(/\s+/g, ' ').trim();
        inputRef.current.textContent = pastedText;

        deriveSuggestion(inputRef.current.textContent)

        focusInputEnd();

    }

    return (
        <span
            ref={inputRef}
            contentEditable
            suppressContentEditableWarning={true}
          
            className={`editable-value admin-content-editable-input`}

            tabIndex="0"
            maxLength={maxLength}
            placeholder={placeholder}

            onFocus = {(e) => { 
                setIsFocused(true); 
                if(onFocus){
                    onFocus(e);
                }
                handleInput(e);
            }}

            onBlur  = {(e) => {
                setIsFocused(false);
                if( onBlur ){
                    onBlur(e);
                }
            }}
            
            onKeyDown = {(e)=>{preventContentEditableKeys(e)}}
            onPaste   = {(e)=>{handlePaste(e)}}
            onKeyUp   = {(e)=>{handleInput(e)}}
            onChange  = {(e)=>{handleInput(e)}}

            onInput   = {(e)=>{ deriveSuggestion( inputRef.current.textContent ) }}
            
        ></span>
    );
}, (prevProps, nextProps) =>
    prevProps.field.value === nextProps.field.value &&
    prevProps.form === nextProps.form
);

export default React.memo(MemoizedInputChild);