import { Component , cloneElement, toChildArray, createRef} from "preact";
import {createPortal} from "preact/compat";
import { connect } from 'react-redux';
import { helpers } from "@cargo/common";
import { toVdom } from "./gallery/helpers";
import SelectionStatus from "./../overlay/selection-status";

import _ from 'lodash';
import register from "./register"

import { withPageInfo } from "./page-info-context";

const textIconMap = new Map();


class TextIcon extends Component {

	constructor() {
		super();
		this.state = {
			iconElement: {
				imgVdom: null,
				customDimensions: {
					width: 32,
					height: 32
				}
			},
		}

	}

	render(props, state){
		const ratio = state.iconElement.customDimensions.width/state.iconElement.customDimensions.height;
		return <>
		{createPortal(<>
			<style id="text-icon">{`
		:host {
			cursor: ${props.adminMode ? 'text': 'unset'};
			aspect-ratio: ${ratio};
			height: 1.2em;
			width: auto;
		    contain: layout;
		    margin-top: -1em;
		    margin-bottom: -.25em;
		    display: inline-block;
		    vertical-align: baseline;
			position: relative;
		}

		:host, *{
		    -moz-user-select: text;
		    -webkit-user-select: text;
		    -ms-user-select: text;
		    user-select: text; 		
		}

		img {
			pointer-events:none;
		}
		
		svg, img {
			object-fit: cover;
			display: block;
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			fill: currentColor;
		}
					`}
				</style>
				{this.state.iconElement.imgVdom}
				{this.props.adminMode ? <SelectionStatus baseNode={this.props.baseNode} inFront={false}/> : null}

				</>, this.props.baseNode.shadowRoot)
			}
		</>

	}



	getIconSVG = ()=>{
		new Promise((resolve, reject) => {

			const iconName = this.props.icon || null;

			if( iconName ){

				if( textIconMap.has(iconName)){

					resolve(textIconMap.get(iconName));

				} else {

					const customURL = iconName.indexOf('https://') === 0;
					const url = customURL ? iconName : PUBLIC_URL+ '/icon/icons/'+iconName+'.svg';

					let fileExt = url.split('.')?.pop() || '';

					if( ['jpeg', 'jpg', 'png', 'gif', 'webp'].indexOf(fileExt.toLowerCase()) != -1 ){

						const img = document.createElement('img');
						img.src = url;
						img.onload =()=>{
							const imgVdom = <img part="img" src={iconName} width={img.naturalWidth} height={img.naturalHeight} />;
							textIconMap.set(iconName, {
								imgVdom,
								customDimensions: {
									width: img.naturalWidth,
									height: img.naturalHeight
								}
							});
							img.onload = null;
							resolve(textIconMap.get(iconName));							
						}

					} else if ( fileExt =='svg'){					

						fetch(url,{
							mode: 'cors'
						}).then((response) => response.ok ? response.text() : reject('bad response')).then(result=>{

							const div = document.createElement('div');
							div.innerHTML = result;

							const customDimensions = {
								width: 32,
								height: 32,
							}
							
							const imgVdom = Array.from(div.children).map((el, index)=>{

								const isSvg = el.tagName.toLowerCase() =='svg';
								if( isSvg && el.hasAttribute('width') && el.hasAttribute('height')){
									customDimensions.width = el.getAttribute('width')
									customDimensions.height = el.getAttribute('height')
								}

								return toVdom(el, null, {
									part: isSvg ? 'svg' : index,
									key: iconName +'-'+index,
									draggable: true,
								})
							})

							if( imgVdom.length > 0){

								textIconMap.set(iconName, {
									imgVdom,
									customDimensions
								});

								if(iconName !== this.props.icon){
									reject('Icon name mismatch');
									return;
								}							
								resolve(textIconMap.get(iconName))
							} else {
								reject("Icon not found at ", url)
							}

						}).catch((error)=>{
							reject(error)
						})	
					} else {
						reject("Invalid url")
					}
			
				}

			} else {
				reject('Icon not found', iconName)				
			}
		}).then(result=>{

			this.setState({
				iconElement: result
			})
		}).catch((e)=>{
			console.log('icon loading failed:',e); 
		});
	}

	onDragStart = (e)=>{
		e.dataTransfer.setData("text-icon-drag", "true");		
	}

	componentDidMount(){
		this.props.baseNode.addEventListener('dragstart', this.onDragStart)
		while(this.props.baseNode.childNodes[0]){
			this.props.baseNode.childNodes[0].remove();
		}	
		this.getIconSVG();
	}

	componentWillUnmount(){
		this.props.baseNode.removeEventListener('dragstart', this.onDragStart)
	}

	componentDidUpdate(prevProps){
		if (prevProps.icon !== this.props.icon){
			this.getIconSVG();
		}
	}

}


TextIcon.defaultProps = {
	icon: null,
}

const ConnectedTextIcon = withPageInfo(connect(
    (state, ownProps) => {
        return {
            adminMode: state.frontendState.adminMode
        };
    }
)(TextIcon));


register(ConnectedTextIcon, 'text-icon', [
	'icon',
], {
	delegatesFocus: true
})

export default TextIcon;

