import { Component, createElement, toChildArray, createRef } from "preact";
import { connect } from 'react-redux';

import { getImageWithOptions} from "../../helpers";

import { forwardRef } from 'preact/compat';
import _ from 'lodash';

class Iframe extends Component {

	constructor(props) {
		super(props);
	}

	render(props){
		const {
			model,
			src,
			isLazyLoadable,
		} = props;

		const usableSrc = model?.is_url ? model.url : src;

		return <iframe part='media' frameborder="0" onload={this.onLoad} ref={this.props.mediaRef} src={isLazyLoadable ? usableSrc : ''} />	
	}

	onLoad=()=>{
		if( this.props.onLoad){
			this.props.onLoad();
		}
	}
}

export default 	forwardRef((props, ref) => {
	return <Iframe {...props} mediaRef={ref} />;
})

