import { Component , cloneElement, toChildArray, render, createRef} from "preact";
import { createPortal } from "preact/compat"

import { connect } from 'react-redux';
import { helpers } from "@cargo/common";
import { withPageInfo } from "./page-info-context";
import windowInfo from "../window-info"
import { subscribe, unsubscribe, dispatch } from '../../customEvents';
import DigitalClockEditor from '../overlay/digital-clock-editor';

import _ from 'lodash';
import register from "./register";

// run all clocks using universal timer
let clockSet = new Set();

let clockAnimationFrame = null;


const days = [
	'sunday',
	'monday',
	'tuesday',
	'wednesday',
	'thursday',
	'friday',
	'saturday',
]

const months = [
	'january',
	'february',
	'march',
	'april',
	'may',
	'june',
	'july',
	'august',
	'september',
	'october',
	'november',
	'december',
]

const timeValues = [
	'time-of-day',
	'day',
	'day-of-month',
	'ordinal',
	'month',
	'month-name',
	'period',
	'roman-year',
	'year',
	'hour',
	'minute',
	'second',
	'centisecond',
]

const updateClocks = (timestamp)=> {

	clockSet.forEach((component) => {
		let time = new Date();		
		component.updateTime(time);
	});
	
	clockAnimationFrame = requestAnimationFrame(updateClocks)	

}


class DigitalClock extends Component {

	constructor(props){
		super(props);

		this.state = {}

	}

	// https://stackoverflow.com/a/9083076
	romanize (num) {
	    if (isNaN(num))
	        return NaN;
	    var digits = String(+num).split(""),
	        key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
	               "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
	               "","I","II","III","IV","V","VI","VII","VIII","IX"],
	        roman = "",
	        i = 3;
	    while (i--)
	        roman = (key[+digits.pop() + (i * 10)] || "") + roman;
	    return Array(+digits.join("") + 1).join("M") + roman;
	}

	pad =(number) => {
		if( number < 10){
			return '0'+ number;
		} else {
			return number.toString();
		}
		
	}


	dateTerms = (term, index)=>{

		switch(term){
			case "{time-of-day}":
				return  <span key={'time-of-day-'+index} part="time-of-day section"></span>
				break;

			case "{day-of-month}":
				return  <span key={'day-of-month-'+index} part="day-of-month section">{this.state['day-of-month']}</span>
				break;

			case "{ordinal}":
				return  <span key={'ordinal'+index} part="ordinal section"></span>
				break;				

			case "{month}":
				return  <span key={'month-'+index} part="month section" >{this.state.month}</span>
				break;

			case "{day}":
				return  <span key={'day-'+index} part="day section" ></span>
				break;

			case "{roman-year}":
				return  <span key={'roman-year-'+index} part="roman-year section">{this.state.romanYear}</span>
				break;

			case "{centisecond}":
				return  <span key={'roman-year-'+index} part="centisecond section">{this.state.centisecond}</span>
				break;

			case "{year}":
				return  <span key={'year-'+index} part="year section">{this.state.year}</span>
				break;

			case "{month-name}":
				return  <span key={'month-name-'+index} part="month-name section" ></span>
				break;				

			case "{hour}":
				return  <span key={'hour-'+index} part="hour section">{this.props['twentyfour-hour'] ? this.state['24hour'] : this.state['12hour']}</span>
				break;

			case "{minute}":
				return  <span key={'minute-'+index} part="minute section">{this.state.minute}</span>
				break;

			case "{second}":
				return  <span key={'second-'+index} part="second section">{this.state.second}</span>
				break;

			case "{period}":
				return  <span key={'period-'+index} part="period section" ></span>
				break;				

		}
		return null;	
	}


	updateTime =(time)=>{

		const padHours = this.props['pad-hour'] || this.props['pad'];
		const padMinute = this.props['pad-minute'] || this.props['pad'];
		const padSecond = this.props['pad-second'] || this.props['pad'];
		const padDate = this.props['pad-day-of-month'] || this.props['pad'];
		const padMonth = this.props['pad-month'] || this.props['pad'];

		if( this.props.timezone !== 'local'){
			const localOffset = time.getTimezoneOffset();	

			let parsedTimezone = this.props.timezone

			if( this.props.timezone.indexOf(':') > -1 ){
				const timezoneArray = this.props.timezone.split(':');
				// just discard anything more finegrained than minutes...
				timezoneArray.length = 2;

				parsedTimezone = parseFloat(timezoneArray[0])+parseFloat(timezoneArray[1] || 0)/60;
			} 

			const offset = ( -(-localOffset -parseFloat(parsedTimezone*60)) || 0) ;
			const timestamp = Date.now();

			time = new Date(timestamp+offset*60000)
		}
		
		const date = padDate ? this.pad(time.getDate()) : time.getDate(),
			day = days[time.getDay()],		
			month = time.getMonth(),
			year = time.getFullYear(),

			hours = time.getHours(),
			minute = time.getMinutes(),
			second = time.getSeconds(),
			millisecond = time.getMilliseconds();


		const timeOfDay = Math.floor(hours/3)*3;

		this.setState({
			day,
			['day-of-month']: date,
			['time-of-day']: timeOfDay+'-'+(parseInt(timeOfDay)+3),
			['ordinal']: date,
			['month-name']: months[month],
			month: padMonth ? this.pad(month+1) : month+1,
			romanYear: this.romanize(year),
			year: this.props['truncate-year'] ? year.toString().substring(2,4) : year,
			period: hours >= 12 ? 'pm': 'am',
			['12hour']: padHours ? this.pad((hours%12== 0 ? 12: hours%12)) : (hours%12== 0 ? 12: hours%12),
			['24hour']: padHours ? this.pad(hours) : hours,
			minute: padMinute ? this.pad(minute) : minute,
			second: padSecond ? this.pad(second) : second,
			centisecond: this.pad(Math.floor(millisecond/10)),
		});
	}

	render(props, state){

		const {
			pageInfo,
			adminMode,
			value,
		} = this.props;

		let dateArray = [];

		let singleDateVal
		// if the value is a non-templated string, look for a singular value to match it
		if( value.indexOf('{') == -1){
			singleDateVal = this.dateTerms('{'+value+'}');
		}

		if( singleDateVal){
			dateArray.push(singleDateVal);

		} else {

			const termsRegex = new RegExp("{"+timeValues.join('}|{')+'}', 'gi');

			let dateMatches = [...(value).matchAll(termsRegex)];

			let lastIndex = 0;

			// if no matches in the value, push the default value
			if( dateMatches.length == 0){

				dateArray.push(this.dateTerms('{second}'));

			} else {
				dateMatches.forEach((match, matchIndex)=>{
					const el = this.dateTerms(match[0], match.index)
					if( el){
						if( lastIndex != match.index){
							dateArray.push(<span key={match.index} part="text">{value.substring(lastIndex, match.index)}</span>);
						}
						lastIndex = match.index+match[0].length;	
						dateArray.push(el);	
					}

					if( matchIndex === dateMatches.length-1){
						dateArray.push(<span key={match.index+'-last'} part="text">{value.substring(lastIndex, value.length)}</span>);
					}
					
				});				
			}


		}

		return createPortal(
			<>
				{adminMode && pageInfo.isEditing && !helpers.isServer && <DigitalClockEditor
						{...this.props}
						clockInstance={this.props.baseNode}
					/>
				}				
				<style>{`

					:host {

						--0-3: "late night";
						--3-6: "early morning";
						--6-9: "morning";
						--9-12: "late morning";
						--12-15: "afternoon";
						--15-18: "late afternoon";
						--18-21: "evening";
						--21-24: "late evening";

						--am: "AM";
						--pm: "PM";

						--sunday: "Sunday";
						--monday: "Monday";
						--tuesday: "Tuesday";
						--wednesday: "Wednesday";
						--thursday: "Thursday";
						--friday: "Friday";
						--saturday: "Saturday";

						--january: "January";
						--february: "February";
						--march: "March";
						--april: "April";
						--may: "May";
						--june: "June";
						--july: "July";
						--august: "August";
						--september: "September";
						--october: "October";
						--november: "November";
						--december: "December";

						--ordinal-1: "first";
						--ordinal-2: "second";
						--ordinal-3: "third";
						--ordinal-4: "fourth";
						--ordinal-5: "fifth";
						--ordinal-6: "sixth";
						--ordinal-7: "seventh";
						--ordinal-8: "eighth";
						--ordinal-9: "ninth";
						--ordinal-10: "tenth";
						--ordinal-11: "eleventh";
						--ordinal-12: "twelfth";
						--ordinal-13: "thirteenth";
						--ordinal-14: "fourteenth";
						--ordinal-15: "fifteenth";
						--ordinal-16: "sixteenth";
						--ordinal-17: "seventeenth";
						--ordinal-18: "eighteenth";
						--ordinal-19: "nineteenth";
						--ordinal-20: "twentieth";
						--ordinal-21: "twenty-first";
						--ordinal-22: "twenty-second";
						--ordinal-23: "twenty-third";
						--ordinal-24: "twenty-fourth";
						--ordinal-25: "twenty-fifth";
						--ordinal-26: "twenty-sixth";
						--ordinal-27: "twenty-seventh";
						--ordinal-28: "twenty-eighth";
						--ordinal-29: "twenty-ninth";
						--ordinal-30: "thirtieth";
						--ordinal-31: "thirty-first";

						cursor: inherit;
					    display: inline-block;
					    position: relative;
					}

					[part*="section"]{
						font-variant: tabular-nums;
					}

					[part="ordinal section"]:after {
						content: var(--ordinal-${this.state['day-of-month']}, "${this.state['day-of-month']}");
					}

					[part="time-of-day section"]:before {
						content: var(--${this.state['time-of-day']});
					}

					[part="period section"]:before {
						content: var(--${this.state.period});
					}				

					[part="day section"]:before {
						content: var(--${this.state.day});
					}

					[part="time-of-day section"]:before {
						content: var(--${this.state['time-of-day']});
					}

					span[part="month-name section"]:before {
						content: var(--${this.state['month-name']});
					}

					
				`}</style>
				{dateArray}
				
			</>, this.props.baseNode.shadowRoot)
	}

	onViewportIntersectionChange = (data)=>{
		const visible = data.position === 'inside';
		this.setState({
			visible
		});
	}


	componentDidMount(){

		subscribe(this.props.baseNode, 'viewportIntersectionChange', this.onViewportIntersectionChange);

		// if this is the first clock on the page, start the animation loop 
		if ( clockSet.size == 0){
			cancelAnimationFrame(clockAnimationFrame);
			clockAnimationFrame = requestAnimationFrame(updateClocks)
		}
		this.updateTime(new Date());

		clockSet.add(this)

	}


	componentDidUpdate(prevProps, prevState){

	}

	componentWillUnmount(){

		unsubscribe(this.props.baseNode, 'viewportIntersectionChange', this.onViewportIntersectionChange);

		clockSet.delete(this)

		// reset animation to 0 if this is the last marquee on a page
		if ( clockSet.size == 0){
			cancelAnimationFrame(clockAnimationFrame);
		}

	}


}

DigitalClock.defaultProps = {
	'pad': false,
	'pad-day-of-month': false,
	'pad-hour': false,
	'pad-month': false,	
	'pad-minute': true,
	'pad-second': true,
	'twentyfour-hour': false,
	'truncate-year': false,
	'timezone': 'local',
	'value': 'second',
}

const ConnectedDigitalClock = withPageInfo(connect(
    (state, ownProps) => {
        return {
            adminMode: state.frontendState.adminMode
        };
    }
)(DigitalClock))

register(ConnectedDigitalClock, 'digital-clock', [
	'pad-day-of-month',	
	'pad-hour',
	'pad-month',	
	'pad-minute',
	'pad-second',
	'twentyfour-hour',
	'truncate-year',
	'pad',
	'timezone',
	'analogue',
	'value', 

], {
	renderIntoShadow: true
}) 





