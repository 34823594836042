import { Component, createRef } from "preact";
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../actions';
import { withRouter } from 'react-router';
import _ from "lodash";
import { helpers } from '@cargo/common';
import selectors from "../selectors";
import Page from "./page";
import { overlayDefaults } from "../../../admin/src/defaults/overlay-defaults";
import { memoizeWeak } from "../helpers";
import windowInfo from "./window-info"

const defaultContentPad = {top: 0, bottom: 0};

let activeOverlays = new Array();

export const overlayMethods = {
	closeOverlay: () => {},
	openOverlay: () => {},
	getOverlay: () => {},
	toggleOverlay: () => {},
	handleGlobalClick: () => {},
	getAllOverlays: () => {},
	parseOverlayOptions: (el) => {
		
		// Get all attributes that begin with data-overlay from the link element and parse them into an object
		const attrs = el?.attributes ?? [];
		const openBelow = el?.closest('.page')?.getAttribute('id') ?? false;
		const options = {};

		const findMatchingKey = (str) => {

			if(!str) {
				return null;
			}

			const properties = Object.keys(overlayDefaults).reduce((acc, key) => {
				acc.push(key);
				if (typeof overlayDefaults[key] === 'object') {
					acc.push(...Object.keys(overlayDefaults[key]))
				}
				return acc;
			}, [])

			const match = properties.find(property => property?.toLowerCase() === str?.toLowerCase());
			if (match) {
				return match;
			}
			return null;
		}

		for (let i = 0; i < attrs.length; i++) {
			if (attrs[i].name.startsWith('data-overlay')) {
				let key = findMatchingKey(attrs[i].name.replace('data-overlay-', '').split('-')[0]);
				if (!key) {
					continue;
				}
				switch (key) {
					case 'animateOnOpen':
					case 'animateOnClose':
						if (!options[key]) {
							options[key] = {};
						}
						const subKey = findMatchingKey(attrs[i].name.replace('data-overlay-', '').split('-')[1]);
						if (!subKey) {
							continue;
						}
						options[key][subKey] = attrs[i].value;
						switch (true) {
							case options[key][subKey] === 'true': {
								options[key][subKey] = true;
								break;
							}
							case options[key][subKey] === 'false': {
								options[key][subKey] = false;
								break;
							}
							case !isNaN(options[key][subKey]): {
								options[key][subKey] = Number(options[key][subKey]);
								break;
							}
						}
						break;
					case 'openBelow':
						options[key] = openBelow;
						break;
					default:
						options[key] = attrs[i].value;
				}
				switch (true) {
					case options[key] === 'true': {
						options[key] = true;
						break;
					}
					case options[key] === 'false': {
						options[key] = false;
						break;
					}
					case !isNaN(options[key]): {
						options[key] = Number(options[key]);
						break;
					}
				}
			}
		}
		return options;
	}
}

class OverlayController extends Component {

	constructor(props){

		super(props);

		this.state = {
			activeOverlays: [],
		};

		// use this to manage overlay state in between component render cycles as preact
		// only updates this.state after a render, which causes us to look at old state when
		// making more than one change to this in the same render cycle
		this.intermediateActiveOverlays = this.state.activeOverlays;

		overlayMethods.openOverlay = this.openOverlay;
		overlayMethods.closeOverlay = this.closeOverlay;
		overlayMethods.toggleOverlay = this.toggleOverlay;
		overlayMethods.getOverlay = this.getOverlay;
		overlayMethods.handleGlobalClick = this.handleGlobalClick;
		overlayMethods.getAllOverlays = this.getAllOverlays;

	}

	componentDidMount() {
		this.autoRenderOverlays();
	}

	autoRenderOverlays = (previousAutorenderedOverlays = []) => {

		if(this.props.adminMode) {

			const allowedPIDs = [this.props.PIDToEdit, this.props.overlayBeingPreviewed].filter(val => !!val);

			// if one of the auto rendereable overlays is being edited, force it open
			if(this.props.autoRenderableOverlays.some(page => allowedPIDs.includes(page.id))) {
				this.openOverlay(this.props.PIDToEdit);
			}

			// close all other overlays
			this.state.activeOverlays.forEach(overlay => {
				
				if(!allowedPIDs.includes(overlay.pid)) {
					this.closeOverlay(overlay.pid)
				}

			})

			return;
		}

		const curr = this.props.autoRenderableOverlays.map(page => page.id);
		const prev = previousAutorenderedOverlays.map(page => page.id);

		const removed = _.difference(prev, curr);
		const added = _.difference(curr, prev);

		removed.forEach(pid => {
			this.closeOverlay(pid)
		});

		// stack auto rendered overlays by their page list sort index
		added.sort((a, b) => {
			return this.props.sortMap[b] - this.props.sortMap[a];
		});

		added.forEach(pid => {
			this.openOverlay(pid)
		});

	}

	componentDidUpdate = (prevProps, prevState) => {

		if(
			this.props.autoRenderableOverlays !== prevProps.autoRenderableOverlays
			|| this.props.adminMode && !prevProps.adminMode
		) {
			this.autoRenderOverlays(prevProps.autoRenderableOverlays);
		}

		if(this.props.adminMode !== prevProps.adminMode) {
			// in admin mode we'll render the pid needing editing,
			// if outside admin mode we should render all auto rendereable overlays
			// without looking at previously open overlays, so pass an empty array here.
			this.autoRenderOverlays([]);
		}

		if(this.state.activeOverlays !== prevState.activeOverlays) {

			const added = _.difference(this.state.activeOverlays, prevState.activeOverlays);
			const removed = _.difference(prevState.activeOverlays, this.state.activeOverlays);

			activeOverlays = [...this.state.activeOverlays];

			assignTopmostScrollableOverlay();

			if (added.length > 0) {
				for (const addedOverlay of added) {
					if (addedOverlay.getOverlayOptions().animateOnOpen) {
						addedOverlay.ref?.current?.base.addEventListener('animationend', this.handleAnimationEnd);
					}
				}
			}

		}

	}

	componentWillUnmount = () => {
	}

	handleGlobalClick = (target, originalTarget) => {

		if (this.props.adminMode) {
			// do not close the overlay being edited
			return;
		}

		const closestQuickView = originalTarget.closest('.quick-view');
		if( closestQuickView){
			return
		}		

		// first check to see if we clicked inside .page-content so we do not include page padding
		const closestPid = originalTarget.closest('.page-content')?.closest('.page')?.getAttribute('id');
		const closestOverlay = this.state.activeOverlays.find(overlay => overlay.pid === closestPid);
		const topOverlay = this.state.activeOverlays[this.state.activeOverlays.length - 1];
	
		// If navigating, close any overlays that have closeOnNavigate set to true
		if (
			closestOverlay
			&& closestOverlay.getOverlayOptions().closeOnNavigate === true
			&& target.hasAttribute('href')
			// only handle history and external links
			&& (
				!target.hasAttribute('rel')
				|| target.getAttribute('rel') === "history"
			)
		) {
			this.closeOverlay(closestOverlay.pid);
			return;
		}

		// If clicking out of an overlay, close it
		if(
			topOverlay
			&& topOverlay.getOverlayOptions().closeOnClickout === true
			&& closestPid !== topOverlay?.pid
			&& target.tagName !== 'A'
		) {
			this.closeOverlay(topOverlay.pid);
			return;
		}

	}

	handleAnimationEnd = (e) => {
		if (!e.target.classList.contains('page')) {
			return;
		}
		switch (e.animationName) {
			case 'overlayOpen': {
				e.target.classList.remove('overlay-open');
				break;
			}
			case 'overlayClose': {
				e.target.classList.remove('overlay-close');
				const pid = e.target.getAttribute('id');
				this.closeOverlay(pid, true);
				break;
			}
		}
	}

	openOverlay = (pid, additionalOverlayOptions = {}) => {

		if(!pid || this.getOverlay(pid)) {
			// prevent double overlays
			return;
		}

		this.intermediateActiveOverlays = [
			...this.intermediateActiveOverlays,
			{
				pid,
				ref: createRef(),
				getOverlayOptions: () => assembleOverlayOptions(pid, this.props.overlayOptionsByPID[pid], additionalOverlayOptions)
			}
		];

		this.setState({
			activeOverlays: this.intermediateActiveOverlays
		})

	}

	closeOverlay = (pid, skipTransition = false) => {

		if (skipTransition === false) {

			const overlay = this.getOverlay(pid);
			if (!overlay) {
				return;
			}

			const overlayOptions = overlay.getOverlayOptions();
			if (overlayOptions?.animateOnClose?.speed > 0) {
				overlay.ref.current.base.classList.add('overlay-close');
				return;
			}

		}

		this.intermediateActiveOverlays = this.intermediateActiveOverlays.filter(overlay => overlay.pid !== pid);

		this.setState({
			activeOverlays: this.intermediateActiveOverlays
		});

	}

	toggleOverlay = (pid, additionalOverlayOptions) => {

		if(this.getOverlay(pid)) {
			this.closeOverlay(pid);
		} else {
			this.openOverlay(pid, additionalOverlayOptions);
		}

	}

	getAllOverlays = () => {
		return this.state.activeOverlays;
	}

	getOverlay = (pid) => {
		return this.state.activeOverlays.find(overlay => overlay.pid === pid);
	}

	render() {

		return this.state.activeOverlays.map(({pid, getOverlayOptions, ref}) => {

			return <Page 
				key={pid} 
				id={pid} 
				contentPad={defaultContentPad}
				isOverlay={true}
				overlayOptions={getOverlayOptions()}
				ref={ref}
			/>

		});

	}
}

const assembleOverlayOptions = memoizeWeak((pid, pageOverlayOptions, additionalOverlayOptions) => {

	return _.merge(
		{}, 
		// populate defaults
		overlayDefaults,
		// overwrite defaults with any overlay_options set on the page
		pageOverlayOptions,
		// Overwrite defaults and page options with any overlay options passed to openOverlay
		additionalOverlayOptions
	);

})

const getOverlayOptionsByPID = memoizeWeak(pagesById => {
	return pagesById = _.mapValues(pagesById, page => page.overlay_options)
});



if( !helpers.isServer){

	// when initializing a wheel event, we want to 'latch' on to the initial element that's being scrolled
	// whether a normal page or the overlay itself
	// so as to avoid the wheel target jumping while scrolling

	var unlatchTimeout = null;
	var scrollableOverlay = null;
	var latchedScrollTarget = false;
	var cancelEvent = false;
	var lastTouch = null;

	var assignScrollableTarget = (target)=>{

		clearTimeout(unlatchTimeout);

		// 200 after last wheel, allow changing of target
		unlatchTimeout = setTimeout(()=>{
			latchedScrollTarget = false;
			cancelEvent = false;
		}, 200);


		if( !latchedScrollTarget ){

			// in reverse order so the first one found is the topmost overlay
			const overlayElements = [...activeOverlays].reverse().map((overlay)=>{
				return document.getElementById(overlay.pid)?.closest('.overlay-content');
			})

			scrollableOverlay = overlayElements.find(overlayEl=>overlayEl?.contains(target));

			// no wheeled-in overlay? check the top active overlay for an element that could use a scroll
			if( !scrollableOverlay ){


				const potentiallyScrollableOverlay = overlayElements.find((overlayEl,index)=>{

					return overlayEl.classList?.contains('overlay-allow-scroll');

				}) 

				if( potentiallyScrollableOverlay ){
					scrollableOverlay = potentiallyScrollableOverlay;
					cancelEvent = true;

				} else {
					cancelEvent = false;
					scrollableOverlay = null;
				}

		
			// if the wheeled-in overlay is scrollable, specifically redirect scroll to that element
			// this would just be native behavior except that safari doesn't latch to elements correctly
			} else if( scrollableOverlay.classList.contains('overlay-allow-scroll') ){

				cancelEvent = true;

			} else {

				const scrollableOverlayAtTopLevel = overlayElements[0].classList?.contains('overlay-allow-scroll') ? overlayElements[0] : null;

				if( scrollableOverlayAtTopLevel ){

					scrollableOverlay = scrollableOverlayAtTopLevel;
					cancelEvent = true;

				} else {

					const interactedInContentArea = scrollableOverlay.querySelector('.page-content')?.contains(target);

					// if we wheeled in the content area of a non-scrolling overlay with a solid background
					// don't scroll any overlay and cancel the wheel

					const hasBackdrop = scrollableOverlay.querySelector('.backdrop');

					// if scrollable overlay has a background, block scrolling full stop
					if( (!interactedInContentArea && !scrollableOverlay.classList.contains('is-passthrough-overlay') || hasBackdrop) ){
						cancelEvent = true;
						scrollableOverlay = null;

					// if interacted inside the content area and we have a background color, also cancel
					} else if ( interactedInContentArea && !scrollableOverlay.classList.contains('is-content-passthrough-overlay') ){
						cancelEvent = true;
						scrollableOverlay = null;

					} else {
						cancelEvent = false;
						scrollableOverlay = null;
					}					

				}



			}

			latchedScrollTarget = true;		

		}

	}

	var delta = 0;
	var resetDeltaTimeout = null;
	var animatedScrollElement = null;
	var lastTimestamp;
	var scrollInterventionAnimationFrame = null;
	var initialDocumentScroll = 0;
	var initialTouchPosition = null;
	var touchedInOverlay = false;

	var handleTouchMove = (e)=>{

		delta = lastTouch - e.touches[0].clientY;

		lastTouch = e.touches[0].clientY;


		clearTimeout(resetDeltaTimeout);
		resetDeltaTimeout = setTimeout(()=>{
			delta = 0;
		}, 300);

		if( scrollableOverlay ){
			scrollableOverlay.scrollTop = scrollableOverlay.scrollTop + delta;	
		}

		if (cancelEvent || touchedInOverlay ){
			e.preventDefault();
			e.stopPropagation();
		}

		if( cancelEvent || scrollableOverlay){
			document.scrollingElement.scrollTop = initialDocumentScroll;
		}


	}

	var handleWheel = (e)=>{

		delta = e.deltaY;
		assignScrollableTarget(e.target);
	
		if (cancelEvent ){
			e.preventDefault();
		}

		if( scrollableOverlay ){			
			scrollableOverlay.scrollTop = scrollableOverlay.scrollTop + delta;	
		} 		
		
	}


	// animate the 'inertia' scroll 
	var scrollInterventionAnimation = (timestamp)=>{

		if( !animatedScrollElement){
			return;
		}

		const timeDelta = lastTimestamp == 0 ? 16 : timestamp - lastTimestamp;

		lastTimestamp = timestamp;

		delta = delta *.95;
		
		const currentScrollTop = animatedScrollElement.scrollTop;
		animatedScrollElement.scrollTop = animatedScrollElement.scrollTop+(delta*(timeDelta/16.667));
		const scrollMotion =  animatedScrollElement.scrollTop - currentScrollTop;

		if( Math.abs(delta) > 0.05 && scrollMotion != 0){
			scrollInterventionAnimationFrame = requestAnimationFrame(scrollInterventionAnimation);
		} else {
			animatedScrollElement = null;
			document.scrollingElement.style.overflow = '';
			document.scrollingElement.style.overscrollBehavior = '';
			document.scrollingElement.scrollTop = initialDocumentScroll;
		}		
	}

	window.addEventListener('wheel', handleWheel, {passive: false});

	window.addEventListener('touchstart', (e)=>{

		animatedScrollElement = null;
		cancelAnimationFrame(scrollInterventionAnimationFrame);
		assignScrollableTarget( e.touches[0].target);
		delta = 0;
		initialTouchPosition = e.touches[0].clientY;

		touchedInOverlay = e.touches[0].target.closest('.overlay-content')

		if( touchedInOverlay){

			const interactedInContentArea = touchedInOverlay.querySelector('.page-content')?.contains(e.touches[0].target);
			const hasBackdrop = touchedInOverlay.querySelector('.backdrop');

			// if scrollable overlay has a background, block scrolling full stop
			if(
				(!interactedInContentArea && touchedInOverlay.classList.contains('is-passthrough-overlay') && !hasBackdrop) ||
				(interactedInContentArea && touchedInOverlay.classList.contains('is-content-passthrough-overlay'))
			){
				touchedInOverlay = null;
			}
		}

		if( scrollableOverlay || touchedInOverlay) {
			initialDocumentScroll = document.scrollingElement.scrollTop;

			document.scrollingElement.style.overflow = 'hidden';
			document.scrollingElement.style.overscrollBehavior = 'none';
			document.scrollingElement.scrollTop = initialDocumentScroll;

			lastTouch = e.touches[0].clientY;
			document.body.addEventListener('touchmove', handleTouchMove, {passive: false});			
		}

	});

	document.body.addEventListener('touchend', (e)=>{

		document.body.removeEventListener('touchmove', handleTouchMove, {passive: false});

		if( scrollableOverlay && Math.abs(delta) > 2){			
			animatedScrollElement = scrollableOverlay;
			clearTimeout(resetDeltaTimeout);
			lastTimestamp = window?.performance?.now?.() ?? 0;
			scrollInterventionAnimationFrame = requestAnimationFrame(scrollInterventionAnimation);
		} else {
			document.scrollingElement.style.overflow = '';
			document.scrollingElement.style.overscrollBehavior = '';
		}

		const deltaFromInitial = initialTouchPosition - e.changedTouches[0].clientY;
		if(!animatedScrollElement && Math.abs(deltaFromInitial) < 10 ){
			overlayMethods.handleGlobalClick(e.changedTouches[0].target, e.changedTouches[0].target);
		}

	}, {passive: false});


		
	windowInfo.on('window-resize', ()=>{
		assignTopmostScrollableOverlay();
	})

	var assignTopmostScrollableOverlay = _.debounce(()=>{


		// in reverse order so the first one found is the topmost overlay
		const reversedOverlays = [...activeOverlays].reverse();
		let topmostScrollableOverlayIndex = reversedOverlays.findIndex((overlay)=>{
			const overlayEl = overlay.ref?.current?.base.closest('.overlay-content');
			if( !overlayEl){
				return false
			}
			return overlayEl.classList.contains('overlay-allow-scroll') && !overlayEl.classList.contains('overlay-close');
		});



		reversedOverlays.forEach((overlay, index)=>{
			const overlayEl = overlay.ref?.current?.base.closest('.overlay-content');

			if( !overlayEl){
				return;
			}

			if(index==0){
				overlayEl.classList.add('top-overlay');
			} else {
				overlayEl.classList.remove('top-overlay');
			}

			if( topmostScrollableOverlayIndex == -1){
				overlayEl.classList.remove('top-scrollable-overlay');				
				overlayEl.classList.remove('behind-top-scrollable-overlay');					
			} else if( index > topmostScrollableOverlayIndex){
				overlayEl.classList.remove('top-scrollable-overlay');
				overlayEl.classList.add('behind-top-scrollable-overlay');
			} else if ( index == topmostScrollableOverlayIndex){
				overlayEl.classList.add('top-scrollable-overlay');
				overlayEl.classList.remove('behind-top-scrollable-overlay');
			} else {
				overlayEl.classList.remove('top-scrollable-overlay');				
				overlayEl.classList.remove('behind-top-scrollable-overlay');				
			}

		});


		if( topmostScrollableOverlayIndex > -1){
			document.body.classList.add('has-scrollable-overlay');
		} else {
			document.body.classList.remove('has-scrollable-overlay');
		}

	}, 30);

	assignTopmostScrollableOverlay();

}



function mapReduxStateToProps(state, ownProps) {

	return {
		adminMode : state.frontendState.adminMode,
		PIDToEdit : state.frontendState.PIDToEdit,
		overlayBeingPreviewed : state.frontendState.overlayBeingPreviewed,
		overlayOptionsByPID: getOverlayOptionsByPID(state.pages.byId),
		sortMap: state.structure.bySort,
		autoRenderableOverlays: selectors.getAutoRenderableOverlaysForSet(state, ownProps.pinContextPID, ownProps.match?.params?.page)
	};

}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		fetchContent: actions.fetchContent,
	}, dispatch);
}

export default withRouter(connect(
	mapReduxStateToProps,
	mapDispatchToProps
)(
	OverlayController
));

export {activeOverlays}
