import React, {Component, Fragment, useState, useRef, useEffect, useMemo } from 'react';
import { deviceType } from "@cargo/common/helpers";
import _ from 'lodash';
import './content-editable-input.scss';
import MemoizedInputChild from './content-editable-input-child';


export const ContentEditableInput = ({field, className, parentClassName, form, label, placeholder, type, icon, iconClass, noBars, barLeft, barRight, followText, focusOnInit, suggestionArray, onKeyDown, onEnter, onBlur, onFocus, maxLength, onMouseDown }) => {

		// let dataValue = !field.value || field.value === "" || field.value.length === 0 ? placeholder : field.value;

		const inputRef           = useRef();
		const inputParentRef     = useRef();
		const contentEditableRef = useRef();
		const ellipsisRef        = useRef();
		const followTextRef      = useRef();
		const hintRef            = useRef();

		const isTouch = deviceType() === 'touch';

		const [isFocused, setIsFocused ] = useState(false);

		const [suggestion, setSuggestion ] = useState('');
		const [visibleHint, setHint ] = useState('');

		const [displayEllipsis, setDisplayEllipsis ] = useState(false);

		const currentValue = useMemo(() => {
			return field.value;
		}, [field.value]);

		// Track previous value prop
		const prevValueRef = useRef();
		// This use Effect tracks current value vs. previous value
		// On submit or clearing of the input, we remeasure and make sure the 
		// input field isn't too short / not accomidating the width of our placeholder value.
		useEffect(() => {
			// Check prev value against current value
			if( currentValue.length === 0 && prevValueRef?.current?.length >= 1 ){
				setSuggestion('');
				setHint('');
			}

			formatFields();

			//assign current value to prev value ref
			prevValueRef.current = field.value;
		}, [field.value]); //run when the value of field changes

		const formatFields = () => {

			const inputEl            = inputParentRef.current.querySelector('.editable-value');
			const inputElWidth       = inputEl?.offsetWidth || 0;
			const contentEditableWidth = contentEditableRef?.current?.offsetWidth || 0;
			const followingTextWidth = followTextRef?.current?.offsetWidth || 0;
			const hintWidth          = hintRef?.current?.offsetWidth || 0;

			if (
				(inputElWidth + followingTextWidth) > contentEditableWidth 
				||
				(inputElWidth + hintWidth) > contentEditableWidth 
			) {
				if( inputElWidth > contentEditableWidth ){
					setDisplayEllipsis('text');
				} else {
					setDisplayEllipsis('placeholder');
				}
				return
			}

			setDisplayEllipsis(false);

		}

		//Activates scroll behavior on successful type to search match
		const deriveSuggestion = ( value ) => {

			let suggestion = null;

			if( value ){
				//Returns first match found in list...
				suggestion = _.find(suggestionArray, function(suggestionString){
					let inputValLength = value.length;
					let matchThis = suggestionString.substring(0, inputValLength);

					if ( matchThis.toUpperCase() == value.toUpperCase() ){
						return suggestionString
					}
				});
			}

			if( value && suggestion && value.length !== 0 ){
				let visibleHint = suggestion.substring(value.length, suggestion.length );
				setHint( visibleHint );
				setSuggestion( suggestion );
				formatFields();
			} else {
				setHint( '' )
				setSuggestion( '' )
				formatFields();
			}

		}

		const handleSuggestionMouseDown = (e) => {
			if( !e.target.classList.contains('editable-value') && e.target && suggestion && suggestion.length > 0 ){
				// Prevent any blur etc..
				e.preventDefault();
				e.stopPropagation();
				e.nativeEvent.stopImmediatePropagation();
				// Set the value to whatever the suggestion is and focus the end of the input.
				const setAutofillValue = new CustomEvent('set-autofill-from-parent', {
					detail: { 
						name: field.name,
						message: suggestion,
					}
				});

				document.dispatchEvent( setAutofillValue );
			}
		}

		const focusInputFromOutside = (e) => {
			if( inputParentRef.current ){
				let inputEl = inputParentRef.current.querySelector('.editable-value');
				let inputLeft = inputEl ? inputEl.getBoundingClientRect().x : 0;
				let clickX = e.clientX;
				let activeElement = document.activeElement;
				
				if( activeElement && activeElement.classList.contains('editable-value') ){
					return
				}

				if( !e.target.classList.contains('editable-value') && inputEl ){
					inputEl.focus();
					if( clickX > inputLeft ){
						document.execCommand('selectAll', false, null);
						// collapse selection to the end
						document.getSelection().collapseToEnd();
						inputParentRef.scrollLeft = inputParentRef.scrollWidth;
					} else {
						inputParentRef.scrollLeft = 0;
					}
				}
			}
		}

		const inputEl = (
			<div 
				ref={inputParentRef}
                className={`text-input content-editable-input suggest${parentClassName ? ' '+parentClassName : ''}`}
				onClick={focusInputFromOutside}
			>
				<span 
					className={`content-editable${followText ? ' follow-text' : ''}${!followText && !visibleHint ? ' input-clear' : ''}`}
					ref={contentEditableRef}
				>
					<MemoizedInputChild 
						form          = {form}
						field         = {field}
						forwardedRef  = {inputRef}
						
						isFocused     = {isFocused}
						setIsFocused  = {setIsFocused}
						focusOnInit   = {focusOnInit}

						suggestion        = {suggestion}
						deriveSuggestion  = {deriveSuggestion}
						placeholder	      = {placeholder}
						formatFields      = {formatFields}
						setHint 		  = {setHint}
						setSuggestion 	  = {setSuggestion}
						onBlur			  = {onBlur}
						onFocus 		  = {onFocus}
						onEnter 		  = {onEnter}
						onKeyDown		  = {onKeyDown}

						maxLength         = {maxLength}
					/>

					{ visibleHint ? <span 
										className="suggestion" 
										ref={hintRef} 
										onMouseDown={handleSuggestionMouseDown}
									>
										{ visibleHint }
									</span> 
					: null }
					{ followText  ? 
						<span 
							className="following-text" 
							ref={followTextRef}
							onPointerUp={focusInputFromOutside}
							onClick={focusInputFromOutside}
						>
							{ followText }
						</span> 
					: null }
				</span>

				{ followText  ? 
				<span 
					ref={ellipsisRef}
					className={`ellipsis${ displayEllipsis ? ' '+displayEllipsis : '' }`}
					style={displayEllipsis ? {display:'inline-block'} : {display:'none'}}
				>...</span>
				: null }

			</div>
		)

		if( label ){
			return(
				<div className={`label-input-group${className ? ' '+className : ''}`}>
					<div className={`input-label ${noBars ? '' : 'bars'}`} >
						{ label }
					</div>
					{ inputEl }
				</div>
			)
		}

		if( icon ){
			return(
				<div className={`input-icon-group${className ? ' '+className : ''}`}>
					{ inputEl }
					<div className={`input-icon ${iconClass ? iconClass : ''}`} >
						{ icon }
					</div>
				</div>
			)
		}

		return inputEl
}
